import { Form, Formik, FormikErrors, FormikProps, FormikValues, getIn } from 'formik';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import { ValidationError } from 'yup';
import { CreateEmployeeInput, UpdateEmployeeInput } from '../../../API';
import { UserContext, UserContextProps } from '../../../App';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { PlusIcon } from '../../../components/icon/Icon.component';
import Loader from '../../../components/loader/Loader';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import Employment from '../../../forms/createEmployee/Employment';
import { History, startCase } from '../../../forms/createEmployee/History';
import PersonalInfo from '../../../forms/createEmployee/PersonalInfo';
import { updateEmployeeSimple } from '../../../graphql-custom/custom-mutations';
import { createEmployee, updateUser } from '../../../graphql/mutations';
import { getEmployee, listUsers } from '../../../graphql/queries';
import {
  NotificationAlertContext,
  NotificationAlertContextProps,
  NotificationAlertOptions,
} from '../../../layouts/AdminLayout';
import { AuditLogEventType, User, UserRole } from '../../../models';
import { createLog } from '../../../utils/audit-log-utils';
import { get, list, mutate } from '../../../utils/graphql-utils';
import { useErrorHandler } from '../../../utils/notification-utils';
import { allowUserToPerformOperation } from '../../../utils/permissions-utils';
import { createNewUser } from '../../../utils/user-utils';
import { schemaArray } from '../employee-validation-schema';
import { blankEmployee } from './example-employee';

interface EmployeeDataFromApi {
  employee: CreateEmployeeInput;
  systemUser: User | null;
}
interface EmployeeState {
  page: number;
  employee: CreateEmployeeInput;
  systemUser: User | null;
  isSystemUser: boolean;
  systemUserEmail: string | null;
  systemUserRole: UserRole | null;
  systemUserEmailError: ValidationError | null;
  loading: boolean;
}

const tabs = ['Personal', 'Employment', 'History'];
const CreateEmployee: React.FC<RouteComponentProps> = () => {
  let page;
  const pages = [Employment, PersonalInfo];
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();
  if (location.state) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    page = location.state.page;
  }

  const [state, setState] = useState<EmployeeState>({
    page: page ? Number(page) : 0,
    employee: blankEmployee,
    systemUser: null,
    systemUserEmail: null,
    systemUserRole: UserRole.LINE_MANAGER,
    isSystemUser: false,
    systemUserEmailError: null,
    loading: false,
  });
  const handleError = useErrorHandler();
  const active = tabs[state.page];
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const notificationContext = useContext<Partial<NotificationAlertContextProps>>(NotificationAlertContext);

  const getDBUserByEmployeeId = (organisationId: string, employeeId: string, active = true): Promise<User | null> => {
    return new Promise((resolve, reject) => {
      const variables = {
        filter: {
          organisationId: { eq: organisationId },
        },
      };
      list(listUsers, variables)
        .then(res => {
          const users: User[] = res.data && (res.data as { [key: string]: any }).listUsers.items;
          if (users && users.length) {
            const u = users.find((user: User) => user.employee?.id === employeeId);
            resolve(u ? u : null);
          } else resolve(null);
        })
        .catch(error => {
          handleError(error);
          reject(error);
        });
    });
  };

  const loadEmployeeData = (employeeId: string): Promise<EmployeeDataFromApi> => {
    return new Promise((resolve, reject) => {
      get(getEmployee, employeeId)
        .then(async res => {
          if (res.data && (res.data as { [key: string]: any }).getEmployee) {
            const employee: CreateEmployeeInput = (res.data as { [key: string]: any }).getEmployee;
            getDBUserByEmployeeId(currentUser?.organisationId || employee.organisationId, employeeId)
              .then((res: User | null) => {
                resolve({ employee: employee, systemUser: res });
              })
              .catch(error => reject(error));
          } else reject(new Error('No data on graphql response'));
        })
        .catch(error => reject(error));
    });
  };

  useEffect(() => {
    // if the url contains an employee ID, then fetch that employee from the database
    if (id) {
      if (window.location.href.includes('/history')) {
        setState(oldState => ({ ...oldState, loading: true, page: 2 }));
      } else {
        setState(oldState => ({ ...oldState, loading: true }));
      }
      loadEmployeeData(id)
        .then(async res => {
          // const cognitoUserEmail = res.systemUser ? await getEmail(res.systemUser.cognitoSub) : null;
          setState(oldState => ({
            ...oldState,
            employee: res.employee,
            systemUser: res.systemUser,
            systemUserEmail: res.systemUser?.emailAddress ? res.systemUser.emailAddress : null,
            systemUserRole: res.systemUser?.roles.length ? (res.systemUser.roles[0] as UserRole) : null,
            isSystemUser: !!res.systemUser,
            loading: false,
          }));
        })
        .catch(error => handleError(error));
    }
  }, [id]);

  const setErrors = (errors: FormikErrors<FormikValues>, setFieldTouched: (field: string, touched: boolean) => any) => {
    const errorKeys = Object.keys(errors);
    errorKeys.forEach(field => {
      //if fieldArray
      if (Array.isArray(errors[field])) {
        const err = getIn(errors, field);
        const innerArr = Object.keys(err);
        innerArr.forEach((item, index) => {
          let fieldNames;
          if (err[item]) {
            fieldNames = Object.keys(err[item]);
            fieldNames.forEach(name => setFieldTouched(`${field}.${index}.${name}`, true));
          }
        });
      } else {
        setFieldTouched(field, true);
      }
    });
  };
  const handleNext = (validateForm: () => any, setFieldTouched: (field: string, touched: boolean) => void): void => {
    validateForm().then((errors: FormikErrors<CreateEmployeeInput>) => {
      const fieldsWithErrors = Object.keys(errors);
      const hasErrors = fieldsWithErrors.length;
      setErrors(errors, setFieldTouched);
      if (!hasErrors && !state.systemUserEmailError) {
        history.push(location.pathname, { page: state.page + 1 });
        setState(oldState => ({ ...oldState, page: state.page + 1 }));
      }
    });
  };
  const handlePrevious = (): void => {
    history.goBack();
    setState(oldState => ({ ...oldState, page: state.page - 1 }));
  };

  const showUserEmployeeNotification = (employee: CreateEmployeeInput | UpdateEmployeeInput): void => {
    if (notificationContext.showNotificationCallback) {
      const options: NotificationAlertOptions = {
        place: 'tr',
        message: employee.id ? 'Employee updated!' : 'New Employee added!',
        type: 'primary',
        icon: 'tim-icons icon-bell-55',
        autoDismiss: 7,
      };
      notificationContext.showNotificationCallback(options);
    }
  };

  const removeEmptyStrings = (obj: any) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') removeEmptyStrings(obj[key]);
      else if (obj[key] === '') obj[key] = null;
    });
  };

  const handleUserUpdate = (userEmail: string, employee: UpdateEmployeeInput): Promise<User> => {
    return new Promise((resolve, reject) => {
      const variables = {
        filter: {
          emailAddress: { eq: state.systemUserEmail },
        },
      };
      list(listUsers, variables).then(res => {
        const users = res.data && (res.data as { [key: string]: any }).listUsers.items;
        if (users && users.length) {
          const user: User = users[0];
          mutate(updateUser, {
            id: user.id,
            userEmployeeId: employee.id,
          })
            .then(res => {
              resolve((res.data as any).updateUser);
            })
            .catch(error => reject(error));
        } else if (state.systemUserEmail && employee.firstName && employee.lastName && employee.organisationId) {
          createNewUser(
            state.systemUserEmail,
            employee.firstName,
            employee.lastName,
            state.systemUserRole ? [state.systemUserRole] : [UserRole.LINE_MANAGER],
            employee.organisationId,
            employee.id,
          )
            .then(res => {
              createLog(currentUser, AuditLogEventType.USER_CREATED).catch(error => handleError(error));
              resolve(res.user);
            })
            .catch(error => reject(error));
        } else reject(new Error('Invalid user parameters'));
      });
    });
  };

  const onSubmit = async (formValues: FormikValues): Promise<void> => {
    if (
      !currentUser!.userRoles!.includes(UserRole.COMPANY_ADMIN) &&
      !currentUser!.userRoles!.includes(UserRole.SUPER_ADMIN)
    ) {
      handleError(new Error('You do not have permission to perform that operation'));
      return;
    }
    const employee = { ...formValues } as CreateEmployeeInput;
    console.log({ employee });
    employee.firstName = employee.firstName.toLowerCase();
    employee.lastName = employee.lastName.toLowerCase();
    removeEmptyStrings(employee);
    if (!employee.organisationId && currentUser?.organisationId) {
      employee.organisationId = currentUser.organisationId;
    }

    const mutationString = employee.id ? updateEmployeeSimple : createEmployee;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.directManager || employee.directManager === null) delete employee.directManager;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.location || employee.location === null) delete employee.location;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.department || employee.department === null) delete employee.department;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.updatedAt || employee.updatedAt === null) delete employee.updatedAt;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.createdAt || employee.createdAt === null) delete employee.createdAt;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.jobTitle || employee.jobTitle === null) delete employee.jobTitle;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.jobGrade || employee.jobGrade === null) delete employee.jobGrade;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.jobLevel || employee.jobLevel === null) delete employee.jobLevel;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.allocatedManager || employee.allocatedManager === null) delete employee.allocatedManager;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // if (employee.createdAt || employee.createdAt === null) delete employee.jobLevel;
    // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // // @ts-ignore
    // if (employee.createdAt || employee.createdAt === null) delete employee.jobGrade;

    if (currentUser && currentUser.organisationId) {
      await mutate(mutationString, employee)
        .then(async res => {
          const employeeResponse: UpdateEmployeeInput = (res.data as { [key: string]: any })[
            employee.id ? 'updateEmployee' : 'createEmployee'
          ];
          if (currentUser) {
            createLog(
              currentUser,
              employee.id ? AuditLogEventType.EMPLOYEE_EDITED : AuditLogEventType.EMPLOYEE_CREATED,
            ).catch(error => handleError(error));
          }

          if (state.systemUserEmail && employeeResponse) {
            handleUserUpdate(state.systemUserEmail, employeeResponse)
              .then(() => {
                showUserEmployeeNotification(employee);
                history.push('/employees');
              })
              .catch(error => handleError(error));
            //   update user
          } else {
            showUserEmployeeNotification(employee);
            history.push('/employees');
          }
        })
        .catch(error => handleError(error));
    }
  };

  const changeTab = (
    pageTo: number,
    validateForm: () => any,
    setFieldTouched: (field: string, touched: boolean) => any,
  ): void => {
    validateForm().then((errors: FormikErrors<CreateEmployeeInput>) => {
      const hasErrors = Object.keys(errors).length;
      setErrors(errors, setFieldTouched);
      if (pageTo < state.page) {
        history.replace(location.pathname, { page: pageTo });
        setState(oldState => ({ ...oldState, page: pageTo }));
      } else if (!hasErrors) {
        history.push(location.pathname, { page: pageTo });
        setState(oldState => ({ ...oldState, page: pageTo }));
      }
    });
  };

  const onCancel = (): void => {
    history.push('/employees');
  };

  const newCase = async () => {
    if (state.employee && currentUser) {
      await startCase(state.employee, currentUser).then(async res => {
        history.push(`/case/${res.id}`);
        if (currentUser && currentUser.organisationId) {
          createLog(currentUser, AuditLogEventType.CASE_STARTED, res.id).catch(error => handleError(error));
        }
      });
    }
  };

  return (
    <>
      <TopBarComponent
        title={state.employee ? state.employee.firstName + ' ' + state.employee.lastName : 'Employees'}
        subTitle={id ? `Edit Employee Details` : 'Upload New Employee'}
      >
        <AddEmployeeButton />
        <ButtonWithIcons
          title={'new case'}
          leftIcon={<PlusIcon />}
          buttonType={'btn-bd-purple'}
          disabled={!(state.employee.id && currentUser)}
          handleClick={async (): Promise<void> => newCase()}
        />
      </TopBarComponent>
      <div className="content">
        <Card>
          <Formik
            initialValues={state.employee}
            onSubmit={(values): Promise<void> => onSubmit(values)}
            enableReinitialize={true}
            validationSchema={schemaArray[state.page]}
          >
            {({
              validateForm,
              values,
              errors,
              handleSubmit,
              setTouched,
              setFieldTouched,
            }: FormikProps<FormikValues>): ReactElement => (
              <CardBody>
                {state.loading ? (
                  <div className="d-flex justify-content-center mt-5">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <Form>
                      <div>
                        {/*<Tabs tabs={tabs} page={state.page} changeTab={changeTab} hasValidation />*/}
                        <Nav className="nav-pills-info" pills>
                          {tabs
                            .filter(
                              tab =>
                                !(
                                  tab.toLowerCase() === 'history' &&
                                  !allowUserToPerformOperation(currentUser, [
                                    UserRole.COMPANY_ADMIN,
                                    UserRole.SUPER_ADMIN,
                                    UserRole.HR_MANAGER,
                                    UserRole.LINE_MANAGER,
                                    UserRole.LINE_EXECUTIVE,
                                  ])
                                ),
                            )
                            .map((tabName, index) => (
                              <span
                                key={index}
                                style={{ cursor: 'pointer', minWidth: '10vw', height: 'auto' }}
                                onClick={e => changeTab(index, validateForm, setFieldTouched)}
                              >
                                <NavItem>
                                  <NavLink
                                    data-toggle="tab"
                                    className={active === tabName ? 'active' : ''}
                                    style={{
                                      width: '100%',
                                      padding: '0.8vw 3vw',
                                      fontSize: '13px',
                                      lineHeight: '18px',
                                      border: 'none',
                                    }}
                                  >
                                    {tabName}
                                  </NavLink>
                                </NavItem>
                              </span>
                            ))}
                        </Nav>
                      </div>
                      {state.page === 0 && <PersonalInfo />}
                      {state.page === 1 && <Employment employeeId={state.employee.id} />}
                      {state.employee.id && state.page === 2 && <History employee={state.employee} />}
                    </Form>
                    <hr style={{ border: '0.06em solid #adb5bd' }} />
                    <Row className="d-flex justify-content-between">
                      <Col className="align-items-start">
                        <Button
                          className="text-uppercase rounded-0"
                          onClick={handlePrevious}
                          disabled={state.page === 0}
                        >
                          Back
                        </Button>
                        <Button className="text-uppercase rounded-0 btn-simple" onClick={onCancel}>
                          Cancel{' '}
                        </Button>
                      </Col>
                      <Col className="text-right">
                        {(state.page === pages.length - 1 || state.employee.id) && (
                          <Button
                            onClick={(): void => handleSubmit()}
                            type={'submit'}
                            className="text-uppercase rounded-0 btn"
                            disabled={!!state.systemUserEmailError}
                          >
                            {state.employee.id ? 'Save & Close' : 'Submit'}
                          </Button>
                        )}
                        {state.page !== pages.length - 1 && (
                          <Button
                            className="text-uppercase rounded-0"
                            onClick={(): void => handleNext(validateForm, setFieldTouched)}
                            disabled={
                              (state.page === 2 && !state.employee.id) ||
                              (state.page === 2 &&
                                !allowUserToPerformOperation(currentUser, [
                                  UserRole.SUPER_ADMIN,
                                  UserRole.COMPANY_ADMIN,
                                  UserRole.HR_MANAGER,
                                  UserRole.LINE_MANAGER,
                                ]))
                            }
                          >
                            Next
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
              </CardBody>
            )}
          </Formik>
        </Card>
      </div>
    </>
  );
};
export default CreateEmployee;
