import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Column, Data } from './DynamicTable';
import {
  list,
  listActiveChairpersonsByOrganisationId,
  listActiveEmployeesByOrganisationIdOptimised,
} from '../../../utils/graphql-utils';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { UserContext, UserContextProps } from '../../../App';
import DynamicTableLinkable from './DynamicTableLinkable';
import Loader from '../../../components/loader/Loader';
import { useErrorHandler } from '../../../utils/notification-utils';
import { Employee } from '../../../models';
import { filterViewableEmployeesByCurrentUser } from '../../../utils/employee-utils';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';
import BulkButton from '../../../components/BulkUploadButton/BulkUploadtButton';
import { listEmployeesOptimised } from '../../../graphql/queries';
import ApiDataContext from '../../../contexts';
import { notEmpty } from '../../../utils/typescript-utils';

interface EmployeeViewState {
  showModal: boolean;
  data?: Data[];
  loading: boolean;
}

const ViewEmployees: React.FC = () => {
  const [state, setState] = useState<EmployeeViewState>({ showModal: false, data: [], loading: true });
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();

  const employeeListApiData = useContext(ApiDataContext);

  const loadEmployees = async (organisationId: string): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      listActiveEmployeesByOrganisationIdOptimised(organisationId)
        .then(res => {
          console.log(res);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          employeeListApiData.initialEmployeesPage = res;
          const employees = res
            .map((employee: Employee) => {
              // these 2 are added for the dropdown select employee search
              // (employee as any)['label'] = employee.firstName + ' ' + employee.lastName;
              // (employee as any)['value'] = employee.id;
              const emailAddresses = employee?.emails?.filter(notEmpty)?.map(item => item?.address);
              // these 2(label and value) are added for the dropdown select employee search
              (employee as any)['label'] = employee.firstName + ' ' + employee.lastName;
              // (employee as any)['value'] = employee.id;
              (employee as any)['value'] = {
                employeeId: employee.id,
                // emailAddress: emailAddresses[0],
                firstName: employee.firstName,
                lastName: employee.lastName,
              };
              (employee as any)['attendeeName'] = employee.firstName + ' ' + employee.lastName;
              (employee as any)['attendeeEmailAddress'] = emailAddresses?.[0] ?? 'noEmail@labourteq.co.za';
              (employee as any)['emailAddress'] = emailAddresses?.[0] ?? '';
              (employee as any)['attendeeId'] = employee.id;

              (employee as any)['departmentName'] = employee.department ? employee.department.name : '';
              (employee as any)['directManagerName'] = employee.directManager
                ? employee.directManager.firstName + ' ' + employee.directManager.lastName
                : '';
              return employee;
            })
            .sort((a: Employee, b: Employee) => {
              return a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' });
            });

          employeeListApiData.employees = employees;
          employeeListApiData.sortedEmployeesList = employees;
          resolve(employees);
        })
        .catch(error => reject(error));
    });
  };

  const loadEmployees1 = async (currentUser: any, nextToken: string | null = null) => {
    const variables = {
      filter: {
        organisationId: { eq: currentUser.organisationId },
        deleted: { ne: true },
      },
      limit: 50,
    };

    const res = await list<{ listEmployees?: { items: Employee[]; nextToken?: string } }>(
      listEmployeesOptimised,
      variables,
    );

    const employees = res.data?.listEmployees?.items || [];
    employeeListApiData.initialEmployeesPage = employees;
    const respEmployees = employees
      .map((employee: Employee) => {
        const emailAddresses = employee?.emails?.filter(notEmpty)?.map(item => item?.address);
        // these 2(label and value) are added for the dropdown select employee search
        (employee as any)['label'] = employee.firstName + ' ' + employee.lastName;
        // (employee as any)['value'] = employee.id;
        (employee as any)['value'] = {
          employeeId: employee.id,
          // emailAddress: emailAddresses[0],
          firstName: employee.firstName,
          lastName: employee.lastName,
        };
        (employee as any)['attendeeName'] = employee.firstName + ' ' + employee.lastName;
        (employee as any)['attendeeEmailAddress'] = emailAddresses?.[0] ?? 'noEmail@labourteq.co.za';
        (employee as any)['emailAddress'] = emailAddresses?.[0] ?? '';
        (employee as any)['attendeeId'] = employee.id;

        (employee as any)['departmentName'] = employee.department ? employee.department.name : '';
        (employee as any)['directManagerName'] = employee.directManager
          ? employee.directManager.firstName + ' ' + employee.directManager.lastName
          : '';
        return employee;
      })
      .sort((a: Employee, b: Employee) => {
        return a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' });
      });

    employeeListApiData.sortedEmployeesList = respEmployees;

    // display first 50
    const viewableEmployees = filterViewableEmployeesByCurrentUser(respEmployees, currentUser);
    setState(oldState => ({ ...oldState, data: viewableEmployees, loading: false }));

    loadEmployees(currentUser.organisationId)
      .then((items: Employee[]) => {
        const viewableEmployees = filterViewableEmployeesByCurrentUser(items, currentUser);
        setState(oldState => ({ ...oldState, data: viewableEmployees, loading: false }));
      })
      .catch(error => {
        handleError(error);
        setState(oldState => ({ ...oldState, loading: false }));
      });

    listActiveChairpersonsByOrganisationId(currentUser.organisationId)
      .then((items: any) => {
        employeeListApiData.initialChairpersonPage = items;
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (currentUser && currentUser.organisationId) {
      loadEmployees1(currentUser);
    }
  }, [currentUser, handleError]);

  const columns: Column[] = [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'employeeNumber', label: 'Employee Number' },
    { key: 'departmentName', label: 'Department' },
    { key: 'directManagerName', label: 'Manager' },
  ];

  const cleanedData =
    state.data && state.data?.length > 0
      ? state.data
          .filter(el => !el.deleted)
          .map(el => {
            if (el.directManagerID === 'topOfReportingLine') {
              const newObject = el;
              newObject.directManagerName = 'Top of Org';
            }

            return el;
          })
      : [];

  console.log('stateseee', state);
  return (
    <>
      <TopBarComponent title={'Employees'} subTitle={'View Employees'}>
        <AddEmployeeButton />
        <BulkButton />
      </TopBarComponent>
      <div className="content">
        {state.loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <Row>
            <Col className="mb-5" md="12">
              <Card>
                <CardBody>
                  <DynamicTableLinkable data={cleanedData} columns={columns} linkPrefix="create-employee" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ViewEmployees;
