import { CaseData, CasePerformanceShortfall } from '../../WorkflowContainer/workflow-utils';
import { DocumentJSONContent } from '../DocumentEditor';
import { toTitleCase } from '../../../utils/string-utils';
import { formatAppealFinding } from '../common-document-utils';

export const appealFindingsFormFields = [
  'appealProcessBackground',
  'appellantArgumentSummary',
  'employerRepArgumentSummary',
  'newEvidenceSummary',
  'legalPrinciplesApplied',
  'conclusionsReached',
  'appealOutcomes',
  'guiltFindings',
  'overturnedGuiltFindings',
  'sanctionFindings',
  'overturnedSanctionFindings',
];

export const appealFormFields = ['groundsForAppeal', 'expectedOutcome'];

export const performanceImprovementPlanFields: string[] = [
  'performanceShortfalls',
  'performanceGoal',
  'qualityStandard',
  'actionsToBeTakenByEmployee',
  'assistanceRequiredFromEmployer',
  'deadline',
  'pipImpactingFactors',
  'pipStartDate',
  'pipEndDate',
];

export const demotionLetterFields: string[] = [
  'demotionNewJobTitle',
  'demotionNewJobLevel',
  'demotionNewDepartment',
  'demotionNewRemunerationPackage',
  'demotionNewTerms',
  'demotionDate',
  'initial_hearing_hearingDate',
  'initial_hearing_hearingLocation',
  'performanceShortfalls',
  'performanceShortfalls_guilty',
  'finalRemunerationDate',
  'dismissalDate',
  'contactName',
  'contactEmail',
  'contactAddress',
  'other',
];

export const dismissalWithPayLetterFields: string[] = [
  'initial_hearing_hearingDate',
  'initial_hearing_hearingLocation',
  'performanceShortfalls',
  'performanceShortfalls_guilty',
  'finalRemunerationDate',
  'dismissalDate',
  'contactName',
  'contactEmail',
  'contactAddress',
  'other',
];

export const hearingFindingsFormFields: string[] = [
  'hearingBackground',
  'employeeRights',
  'employerRepSummary',
  'employeeRepSummary',
  'legalPrinciples',
  'performanceShortfallConclusion',
  'aggravationFactors',
  'commentsAndMitigatingFactors',
  'conclusion',
  'other',
];

export const pipConclusionLetterFields: string[] = ['other'];

export const agreedDemotionLetterFields: string[] = [
  'demotionNewJobTitle',
  'demotionNewJobLevel',
  'demotionNewDepartment',
  'demotionNewRemunerationPackage',
  'demotionNewTerms',
  'demotionDate',
];

export const noticeOfIncapacityHearingFields = [
  'initial_hearing_hearingDate',
  'initial_hearing_hearingLocation',
  'initial_hearing_hearingTime',
  'initial_hearing_chairpersonFirstName',
  'initial_hearing_chairpersonLastName',
  'performanceShortfalls',
  'other',
];

export const noticeOfAppealIncapacityHearingFields = [
  'appeal_hearing_hearingDate',
  'appeal_hearing_hearingLocation',
  'appeal_hearing_hearingTime',
  'appeal_hearing_chairpersonFirstName',
  'appeal_hearing_chairpersonLastName',
];

export const appealOutcomeFormFields = [
  'appeal_hearing_hearingDate',
  'appealReason',
  'appealFindingOutcomes',
  'appealSanctionOutcomes',
  'appealOutcomeText',
  'other',
];

export const summaryDismissalLetterFields = [
  'initial_hearing_hearingDate',
  'initial_hearing_hearingLocation',
  'performanceShortfalls',
  'performanceShortfalls_guilty',
  'finalRemunerationDate',
  'dismissalDate',
  'contactName',
  'contactEmail',
  'contactAddress',
  'other',
];

export const pipExtensionFollowingHearingFields = [
  'performanceShortfalls',
  'performanceGoal',
  'qualityStandard',
  'actionsToBeTakenByEmployee',
  'assistanceRequiredFromEmployer',
  'deadline',
  'initial_hearing_hearingDate',
  'performanceShortfalls',
  'warningValidityPeriod',
];

export const pipExtensionFields = [
  'performanceShortfalls',
  'performanceGoal',
  'qualityStandard',
  'actionsToBeTakenByEmployee',
  'assistanceRequiredFromEmployer',
  'deadline',
  'shortfallDescriptions',
  'performanceDiscussionDate',
];

const getAppealReasonText = (shortfalls: CasePerformanceShortfall[]): string => {
  const performanceShortfalls = shortfalls.filter(
    (item: CasePerformanceShortfall) => item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
  );
  if (performanceShortfalls.filter((item: CasePerformanceShortfall) => item.didEmployeeAppealRuling === 'YES').length) {
    return 'findings of guilt';
  } else if (
    performanceShortfalls.filter((item: CasePerformanceShortfall) => item.didEmployeeAppealSanction === 'YES').length
  ) {
    return 'sanction';
  } else return '';
};

const getAppealOutcomeText = (caseData: CaseData): string => {
  const performanceShortfalls = caseData.performanceShortfalls.filter(
    (item: CasePerformanceShortfall) => item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
  );
  if (
    performanceShortfalls.filter(
      (item: CasePerformanceShortfall) => item.appeal_hearing_chairpersonsFinding === 'OVERTURN',
    ).length === performanceShortfalls.length
  ) {
    return 'All charges are dropped, and the disciplinary process is concluded.';
  } else if (
    !performanceShortfalls.filter(
      (item: CasePerformanceShortfall) =>
        item.appeal_hearing_chairpersonsFinding === 'OVERTURN' ||
        item.appeal_hearing_chairpersonsSanction === 'OVERTURN',
    ).length
  ) {
    return 'The findings of the original disciplinary hearing remain as is.';
  } else if (caseData.revisedOverallSanction && caseData.revisedOverallSanction !== caseData.overallSanction) {
    return (
      'The overall sanction is changed from ' +
      toTitleCase(caseData.overallSanction, '_') +
      ' to ' +
      toTitleCase(caseData.revisedOverallSanction, '_') +
      '.'
    );
  } else return '';
};

const getRelevantValues = (caseData: CaseData): { [key: string]: string | string[] } => {
  const appealOutcomeText = getAppealOutcomeText(caseData);
  const appealReasonText = getAppealReasonText(caseData.performanceShortfalls);
  const performanceShortfalls = caseData.performanceShortfalls.map(item => item.shortfallDescription);
  return {
    appealOutcomeText,
    appealReasonText,
    performanceShortfalls,
  };
};

export const prepareFieldValuesPoorPerformance = (caseData: CaseData): DocumentJSONContent => {
  const { appealOutcomeText, appealReasonText, performanceShortfalls } = getRelevantValues(caseData);

  console.log('performance short falls', caseData.performanceShortfalls);

  return {
    other: {
      label: 'Additional Comments',
      type: 'textarea',
      value: '',
    },
    warningValidityPeriod: {
      label: 'Warning Period',
      type: 'number',
      value: caseData.warningValidityPeriod ? caseData.warningValidityPeriod : '',
    },
    suspensionDiscussionDate: {
      label: 'Discussion Date',
      type: 'text',
      value: caseData.suspensionDiscussionDate,
    },
    performanceShortfalls: {
      label: 'Performance Shortfalls',
      type: 'array',
      value: performanceShortfalls,
    },
    performanceShortfalls_guilty: {
      label: 'Performance Shortfalls',
      type: 'array',
      value: caseData.performanceShortfalls
        .filter((item: CasePerformanceShortfall) => {
          return caseData.isAppeal
            ? item.appeal_hearing_chairpersonsFinding === 'CONFIRM' ||
                (item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY' && item.didEmployeeAppealRuling === 'NO')
            : item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY';
        })
        .map((item: CasePerformanceShortfall) => item.shortfallDescription),
    },
    qualityStandard: {
      label: null,
      type: 'array',
      value: caseData.performanceShortfalls ? caseData.performanceShortfalls.map(item => item.qualityStandard) : [],
    },
    performanceGoal: {
      label: null,
      type: 'array',
      value: caseData.performanceShortfalls ? caseData.performanceShortfalls.map(item => item.performanceGoal) : [],
    },
    deadline: {
      label: null,
      type: 'array',
      value: caseData.performanceShortfalls ? caseData.performanceShortfalls.map(item => item.deadline) : [],
    },
    shortfallDescriptions: {
      label: null,
      type: 'array',
      value: caseData.performanceShortfalls
        ? caseData.performanceShortfalls.map(item => item.shortfallDescription)
        : [],
    },
    actionsToBeTakenByEmployee: {
      label: null,
      type: 'array',
      value: caseData.performanceShortfalls
        ? caseData.performanceShortfalls.map(item => item.actionsToBeTakenByEmployee)
        : [],
    },
    assistanceRequiredFromEmployer: {
      label: null,
      type: 'array',
      value: caseData.performanceShortfalls
        ? caseData.performanceShortfalls.map(item => item.assistanceRequiredFromEmployer)
        : [],
    },
    appealFindingOutcomes: {
      label: null,
      type: 'array',
      value: caseData.performanceShortfalls
        ? caseData.performanceShortfalls.map((item: CasePerformanceShortfall) => {
            return item.didEmployeeAppealRuling === 'YES'
              ? item.shortfallDescription + ': ' + formatAppealFinding(item.appeal_hearing_chairpersonsFinding!)
              : 'Not Applicable';
          })
        : [],
    },
    appealSanctionOutcomes: {
      label: null,
      type: 'array',
      value: caseData.performanceShortfalls
        ? caseData.performanceShortfalls
            .filter((item: CasePerformanceShortfall) => item.didEmployeeAppealSanction === 'YES')
            .map(
              (item: CasePerformanceShortfall) =>
                item.shortfallDescription + ': ' + formatAppealFinding(item.appeal_hearing_chairpersonsSanction!),
            )
        : [],
    },
    appealOutcomeText: {
      label: null,
      type: 'text',
      value: appealOutcomeText,
    },
    appealReason: {
      label: null,
      type: 'text',
      value: appealReasonText,
    },
    correctiveActions: {
      label: 'Corrective Actions',
      type: 'array',
      value: caseData.performanceShortfalls
        ? caseData.performanceShortfalls.map((item: CasePerformanceShortfall) =>
            item.correctiveAction ? item.correctiveAction : '',
          )
        : [''],
    },
    initial_hearing_chairpersonFirstName: {
      label: "Chairperson's First Name",
      type: 'text',
      value: caseData.initial_hearing_chairpersonFirstName
        ? toTitleCase(caseData.initial_hearing_chairpersonFirstName, ' ')
        : '',
    },
    initial_hearing_chairpersonLastName: {
      label: "Chairperson's First Name",
      type: 'text',
      value: caseData.initial_hearing_chairpersonLastName
        ? toTitleCase(caseData.initial_hearing_chairpersonLastName, ' ')
        : '',
    },
    initial_hearing_hearingTime: {
      label: 'Time',
      type: 'time',
      value: caseData.initial_hearing_hearingTime ? caseData.initial_hearing_hearingTime : '',
    },
    initial_hearing_hearingDate: {
      label: 'Hearing Date',
      type: 'date',
      value: caseData.initial_hearing_hearingDate ? caseData.initial_hearing_hearingDate : '',
    },
    appeal_hearing_hearingDate: {
      label: 'Appeal Hearing Date',
      type: 'date',
      value: caseData.appeal_hearing_hearingDate ? caseData.appeal_hearing_hearingDate : '',
    },
    appeal_hearing_hearingLocation: {
      label: 'Appeal Hearing Location',
      type: 'text',
      value: caseData.appeal_hearing_hearingLocation ? caseData.appeal_hearing_hearingLocation : '',
    },
    appeal_hearing_hearingTime: {
      label: 'Appeal Hearing Time',
      type: 'time',
      value: caseData.appeal_hearing_hearingTime ? caseData.appeal_hearing_hearingTime : '',
    },
    appeal_hearing_chairpersonFirstName: {
      label: 'Appeal Hearing Chairperson First Name',
      type: 'text',
      value: caseData.appeal_hearing_chairpersonFirstName
        ? toTitleCase(caseData.appeal_hearing_chairpersonFirstName, ' ')
        : '',
    },
    appeal_hearing_chairpersonLastName: {
      label: 'Appeal Hearing Chairperson Last Name',
      type: 'text',
      value: caseData.appeal_hearing_chairpersonLastName
        ? toTitleCase(caseData.appeal_hearing_chairpersonLastName, ' ')
        : '',
    },
    initial_hearing_hearingLocation: {
      label: 'Place',
      type: 'text',
      value: caseData.initial_hearing_hearingLocation ? toTitleCase(caseData.initial_hearing_hearingLocation, ' ') : '',
    },
    dismissalDate: {
      label: 'Dismissal Date',
      type: 'date',
      value: caseData.dismissalDate ? caseData.dismissalDate : '',
    },
    finalRemunerationDate: {
      label: 'Final Remuneration Date',
      type: 'date',
      value: caseData.lastRemunerationDate ? caseData.lastRemunerationDate : '',
    },
    suspensionStartDate: {
      label: 'Suspension Start Date',
      type: 'date',
      value: caseData.suspensionStartDate ? caseData.suspensionStartDate : '',
    },
    suspensionEndDate: {
      label: 'Suspension End Date',
      type: 'date',
      value: caseData.suspensionEndDate ? caseData.suspensionEndDate : '',
    },
    returnToWorkDate: {
      label: 'Return to Work Date',
      type: 'date',
      value: caseData.returnToWorkDate ? caseData.returnToWorkDate : '',
    },
    contactName: {
      label: 'Contact Name',
      type: 'text',
      value: caseData.contactPersonInCaseOfCCMA ? toTitleCase(caseData.contactPersonInCaseOfCCMA, ' ') : '',
    },
    contactEmail: {
      label: 'Contact Email',
      type: 'text',
      value: caseData.emailAddress,
    },
    contactAddress: {
      label: 'Contact Address',
      type: 'text',
      value: caseData.physicalAddress,
    },
    demotionNewJobTitleId: {
      label: 'New Job Title',
      type: 'text',
      value: caseData.demotionNewJobTitleId ? caseData.demotionNewJobTitleId : '',
    },
    demotionNewJobTitle: {
      label: 'New Job Title',
      type: 'text',
      value: caseData.demotionNewJobTitle ? caseData.demotionNewJobTitle : '',
    },
    demotionNewJobLevelId: {
      label: 'New Job Level',
      type: 'text',
      value: caseData.demotionNewJobLevelId ? caseData.demotionNewJobLevelId : '',
    },
    demotionNewJobLevel: {
      label: 'New Job Level',
      type: 'text',
      value: caseData.demotionNewJobLevel ? caseData.demotionNewJobLevel : '',
    },
    demotionNewDepartmentId: {
      label: 'New Department',
      type: 'text',
      value: caseData.demotionNewDepartmentId ? caseData.demotionNewDepartmentId : '',
    },
    demotionNewDepartment: {
      label: 'New Department',
      type: 'text',
      value: caseData.demotionNewDepartment ? caseData.demotionNewDepartment : '',
    },
    demotionNewRemunerationPackage: {
      label: 'New Remuneration Package',
      type: 'text',
      value: caseData.demotionNewRemunerationPackage ? caseData.demotionNewRemunerationPackage : '',
    },
    demotionNewTerms: {
      label: 'New Terms',
      type: 'text',
      value: caseData.demotionNewTerms ? caseData.demotionNewTerms : '',
    },
    demotionDate: {
      label: 'Demotion Date',
      type: 'date',
      value: caseData.demotionDate ? caseData.demotionDate : '',
    },
    appealProcessBackground: {
      label: 'State briefly the background of what led to the appeal process:',
      type: 'textarea',
      value: 'This is Free text',
    },
    appellantArgumentSummary: {
      label: 'Summarise arguments of the appellant:',
      type: 'textarea',
      value: 'This is Free text',
    },
    employerRepArgumentSummary: {
      label: 'Summarise arguments of the employer representative:',
      type: 'textarea',
      value: 'This is Free text',
    },
    newEvidenceSummary: {
      label: ' Summarise any NEW evidence considered, if any:',
      type: 'textarea',
      value: 'This is Free text',
    },
    legalPrinciplesApplied: {
      label: 'Legal principles applied:',
      type: 'textarea',
      value: 'This is Free text',
    },
    conclusionsReached: {
      label: 'Conclusions reached:',
      type: 'textarea',
      value: 'This is Free text',
    },
    appealOutcomes: {
      label: 'Appeal outcomes in relation to original incapacity findings. Tick as appropriate',
      type: 'textarea',
      value: 'This is Free text',
    },
    guiltFindings: {
      label: 'Findings on guilt:',
      type: 'textarea',
      value: 'This is Free text',
    },
    overturnedGuiltFindings: {
      label: 'If overturned, new finding(s) with reasons:',
      type: 'textarea',
      value: 'This is Free text',
    },
    sanctionFindings: {
      label: 'Findings on sanction:',
      type: 'textarea',
      value: 'This is Free text',
    },
    overturnedSanctionFindings: {
      label: 'If overturned, new sanction, with reasons:',
      type: 'textarea',
      value: 'This is Free text',
    },
    groundForAppeal: {
      label:
        'Describe why you are dissatisfied with the incapacity hearing outcome. Attach separate sheet if necessary.',
      type: 'textarea',
      value: '',
    },
    expectedOutcome: {
      label:
        'Describe why you are dissatisfied with the incapacity hearing outcome. Attach separate sheet if necessary.',
      type: 'textarea',
      value: '',
    },
    hearingBackground: {
      label: 'State briefly the background of what led to the Incapacity Poor Performance hearing process:',
      type: 'textarea',
      value: '',
    },
    employeeRights: {
      label: 'Record the employee’s rights exercised in the hearing:',
      type: 'textarea',
      value: '',
    },
    employerRepSummary: {
      label: 'Summarise evidence submitted by the employer representative:',
      type: 'textarea',
      value: '',
    },
    employeeRepSummary: {
      label: 'Summarise evidence submitted by the employee and/or his/her representative:',
      type: 'textarea',
      value: '',
    },
    legalPrinciples: {
      label: 'Summarise legal principles applied, if any:',
      type: 'textarea',
      value: '',
    },
    performanceShortfallConclusion: {
      label:
        'Conclusions reached on sub-standard performance/shortfalls or otherwise based on factual evidence and legal principles:',
      type: 'textarea',
      value: '',
    },
    aggravationFactors: {
      label: 'Summarise aggravating factors considered to determine way forward:',
      type: 'textarea',
      value: '',
    },
    commentsAndMitigatingFactors: {
      label: 'Summarise employee comments and mitigating factors considered to determine way forward:',
      type: 'textarea',
      value: '',
    },
    conclusion: {
      label: 'Conclusions reached on way forward:',
      type: 'textarea',
      value: '',
    },
    pipStartDate: {
      label: 'Start Date',
      type: 'date',
      value: caseData.pipStartDate ? caseData.pipStartDate : '',
    },
    pipEndDate: {
      label: 'End Date',
      type: 'date',
      value: caseData.pipEndDate ? caseData.pipEndDate : '',
    },
    pipImpactingFactors: {
      label: 'Factors contributing to poor performance',
      type: 'array',
      value: caseData.pipImpactingFactors ? caseData.pipImpactingFactors : [''],
    },
    performanceDiscussionDate: {
      label: 'Counselling Date',
      type: 'date',
      value: '',
    },
  };
};
