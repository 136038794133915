import React from 'react';
import { Col, Row } from 'reactstrap';
import SearchBox from '../searchBox/SearchBox.component';
import './TopBar.styles.scss';

interface TopBarComponentProps {
  title: string;
  subTitle?: string;
  onSearch?: () => void;
  hideSearch?: boolean;
  color?: string;
  style?: any;
}

// eslint-disable-next-line react/prop-types
const TopBarComponent: React.FC<TopBarComponentProps> = ({ title, subTitle, children, hideSearch, color, style }) => {
  return (
    <div className="content content-top top-bar-container" style={{ background: color, ...style }}>
      <Row className="d-flex align-items-center justify-content-between py-4 px-3">
        <Col className="d-flex flex-column justify-content-center">
          <span className="h2 mb-0 text-default font-weight-normal text-capitalize" style={{ fontSize: '30px' }}>
            {title}
          </span>
          <span
            className="h4 mt-0 mb-0 text-muted font-weight-normal text-capitalize"
            style={{ fontSize: '16px', color: '#6D7278' }}
          >
            {subTitle}
          </span>
        </Col>
        <Col className="col-auto">{!hideSearch && <SearchBox />}</Col>
        <Col className="col-auto">{children}</Col>
      </Row>
    </div>
  );
};

export default TopBarComponent;
