import React, { useContext } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ErrorMessage, FormikValues, useFormikContext } from 'formik';
import FormField from '../../../../../forms/fields/FormField.component';
import { Witness, WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';
import UploaderContainer from '../../../../../components/Uploader/UploaderContainer';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../components/taskContainer/TaskContainer';

const InitialHearingMinutesAndRecordingForm: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const { values } = useFormikContext<FormikValues>();
  const { setFieldValue } = useFormikContext();

  return (
    <Form>
      <Row>
        <Col>
          <Label className="text-default d-block">Confirm All Attendees</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.initial_hearing_isEmployerRepresentativePresent}
                    onChange={(): void => {
                      setFieldValue(
                        'initial_hearing_isEmployerRepresentativePresent',
                        !values.initial_hearing_isEmployerRepresentativePresent,
                      );
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-capitalize">
                      {' '}
                      {values.initial_hearing_employerRepresentativeFirstName +
                        ' ' +
                        values.initial_hearing_employerRepresentativeLastName}
                    </span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.initial_hearing_isChairpersonPresent}
                    onChange={(): void => {
                      setFieldValue(
                        'initial_hearing_isChairpersonPresent',
                        !values.initial_hearing_isChairpersonPresent,
                      );
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-capitalize">
                      {values.initial_hearing_chairpersonFirstName + ' ' + values.initial_hearing_chairpersonLastName}
                    </span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.initial_hearing_isEmployeePresent}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_isEmployeePresent', !values.initial_hearing_isEmployeePresent);
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-capitalize">{props.data.employeeFullName}</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            {values.initial_hearing_hearingWitnesses &&
              values.initial_hearing_hearingWitnesses.map((witness: Witness, index: number) => (
                <div key={index} className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={witness.presentAtHearing}
                        onChange={(): void => {
                          setFieldValue(
                            `initial_hearing_hearingWitnesses.${index}.presentAtHearing`,
                            !values.initial_hearing_hearingWitnesses[index].presentAtHearing,
                          );
                        }}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-capitalize">
                          {witness.firstName + ' ' + witness.lastName}
                        </span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              ))}
          </div>
        </Col>
      </Row>
      {/*<FieldArray name={'otherAttendees'}>*/}
      {/*  {(arrayHelpers: FieldArrayRenderProps) => (*/}
      {/*    <>*/}
      {/*      {!values.otherAttendees && arrayHelpers.push('')}*/}
      {/*      {values.otherAttendees &&*/}
      {/*      values.otherAttendees.isArray() &&*/}
      {/*        values.otherAttendees.length &&*/}
      {/*        values.otherAttendees.map((item: any, index: number) => (*/}
      {/*          <InputRow*/}
      {/*            key={index}*/}
      {/*            index={index}*/}
      {/*            defaultValues={''}*/}
      {/*            addCallback={arrayHelpers.push}*/}
      {/*            removeCallback={arrayHelpers.remove}*/}
      {/*            rowClassName={'mt-2'}*/}
      {/*          >*/}
      {/*            <Col md={4}>*/}
      {/*              <FormGroup>*/}
      {/*                {index === 0 && <Label className="text-capitalize text-default">Other Attendees</Label>}*/}
      {/*                <FormField type={'text'} className="text-capitalize" placeholder={'Attendee'} name={`otherAttendees.${index}`} />*/}
      {/*              </FormGroup>*/}
      {/*            </Col>*/}
      {/*          </InputRow>*/}
      {/*        ))}*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</FieldArray>*/}
      <Row>
        <Col>
          <Label className="text-default d-block mt-4">Confirm Logistics</Label>
        </Col>
      </Row>
      <Row className="mt-2 mb-2">
        <Col md={4}>
          <FormGroup>
            <Label for="Date of Hearing" className="text-default">
              Date of Hearing
            </Label>
            <FormField type={'date'} placeholder="Date of Hearing" name="initial_hearing_hearingDate" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'initial_hearing_hearingDate'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="Time of hearing" className="text-default">
              Time of Hearing
            </Label>
            <FormField type={'time'} placeholder="Time of hearing" name="initial_hearing_hearingTime" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'initial_hearing_hearingTime'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="Hearing Location" className="text-default">
              Location
            </Label>
            <FormField
              type={'text'}
              className="text-capitalize"
              placeholder="Hearing Location"
              name="initial_hearing_hearingLocation"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'initial_hearing_hearingLocation'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="initial_hearing_reasonForDeclining" className="text-default text-capitalize">
              Record if any witness declined to testify with reasons.
            </Label>
            <FormField
              type={'textarea'}
              placeholder="Please Provide Details"
              name="initial_hearing_reasonForDeclining"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'initial_hearing_reasonForDeclining'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <h4 className="text-h5 text-capitalize font-weight-500 mt-3">Upload Hearing Documentation</h4>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="documentary evidence upload" className="text-default text-capitalize">
              Documentary evidence submitted during the hearing
            </Label>
            <UploaderContainer
              fieldName={'initial_hearing_documentaryEvidenceFiles'}
              path={`cases/${props.data.masterProcessInstanceId}`}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="documentary evidence upload" className="text-default text-capitalize">
              Minutes or Recording*
            </Label>
            <UploaderContainer
              fieldName={'initial_hearing_minutesOrRecordingsFiles'}
              path={`cases/${props.data.masterProcessInstanceId}`}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default InitialHearingMinutesAndRecordingForm;
