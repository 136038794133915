import { Button, Table as BootStrapTable } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { TemplateType } from '../../API';
import { get, listActiveEmployeesByOrganisationId } from '../../utils/graphql-utils';
import { getOrganisation, getTemplateDocumentVersion } from '../../graphql/queries';
import { UserContext, UserContextProps } from '../../App';
import { EmailDraftModal } from './EmailDraftModal';
import { DocumentDeliveryMethod, IssueLetterModal } from './IssueLetterModal';
import './DocumentVersionControlTable.styles.scss';
import moment from 'moment';
import { WorkflowComponentProps } from '../../screens/WorkflowContainer/workflow-utils';
import {
  queryHistoricProcessInstancesForDocumentFlows,
  queryHistoricTaskInstances,
  queryHistoricTaskInstancesByDocumentIdAndProcessInstanceId,
} from '../../utils/flowable/flowable-utils';
import { useErrorHandler } from '../../utils/notification-utils';
import { EmailParamsV2, EmailType } from '../../utils/email-utils';
import { AuditLog, Employee, TemplateDocumentVersion, User } from '../../models';
import { toTitleCase } from '../../utils/string-utils';
import { getFileURL } from '../../utils/storage-utils';
import { TableUploader } from './TableUploader';
import { getUserDetails } from '../../graphql-custom/custom-queries';
import Loader from '../loader/Loader';
import { FlowableHistoricTask, FlowableVariable } from '../../utils/flowable/flowable-types';
import { completeDocumentEmailTask, completeDocumentTask } from '../../utils/email-utils';
import { EmailTrackerTable } from '../emailAuditLogTable/emailTrackerTable';

interface DocumentTableProps extends WorkflowComponentProps {
  processInstanceId: string;
  templateType: TemplateType;
  completeTask: (flowableVariables?: FlowableVariable[]) => void;
  // additionalAttachmentFormKeys is used to specify the form value of a document to be added to the issued document. This is used
  // when the issued document follows from a chairperson's ruling and must therefore be accompanied by the ruling.
  additionalAttachmentFormKeys?: string[];
  customEmailTypeForIssueDocumentMail?: EmailType;
}

interface DocumentEvent {
  event: AuditLog;
  document: TemplateDocumentVersion;
  isLatest: boolean;
  canEdit: boolean;
  documentTaskKeys: (string | null)[];
  deliveryMethod: string | undefined;
}

export interface DocumentTask {
  historicTask: FlowableHistoricTask;
  activeTaskDefinitionKeys: string[];
  doc: TemplateDocumentVersion;
  processInstanceId: string;
  user?: User;
  isLatestTask: boolean;
}

export interface DocumentVersionControlTableState {
  employees: Employee[];
  employee: Employee | null;
  openModal: TemplateDocumentModal | null;
  documentEvents: DocumentEvent[];
  documentTasks: DocumentTask[];
  isDownloading: boolean;
  facilitiesEmailAddress: string | null;
  itEmailAddress: string | null;
  isLoading: boolean;
}

interface TemplateDocumentModal {
  type: ModalType;
  document: TemplateDocumentVersion;
  documentTask: DocumentTask;
}

export enum ModalType {
  ISSUE_DOCUMENT_TO_EMPLOYEE = 'ISSUE_DOCUMENT_TO_EMPLOYEE',
  EMAIL_DRAFT_DOCUMENT_FOR_APPROVAL = 'EMAIL_DRAFT_DOCUMENT_FOR_APPROVAL',
}
export const DocumentVersionControlTable: React.FC<DocumentTableProps> = (props: DocumentTableProps) => {
  const columnHeadings = ['Date', 'Version', 'Action', 'Actioned By', 'Status'];
  const [state, setState] = useState<DocumentVersionControlTableState>({
    employees: [],
    employee: null,
    openModal: null,
    documentEvents: [],
    documentTasks: [],
    isDownloading: false,
    itEmailAddress: null,
    facilitiesEmailAddress: null,
    isLoading: false,
  });
  const handleError = useErrorHandler();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const loadEmployeesAndConfig = (organisationId: string) => {
    Promise.all([listActiveEmployeesByOrganisationId(organisationId), get(getOrganisation, organisationId)])
      .then((res: any[]) => {
        const employees: Employee[] = res[0];
        const organisation = (res[1].data as any)?.getOrganisation;
        const employee = employees.find((item: Employee) => item.id === props.data.caseData.employeeId);
        setState((oldState: DocumentVersionControlTableState) => ({
          ...oldState,
          facilitiesEmailAddress: organisation?.facilitiesEmail,
          itEmailAddress: organisation?.itEmail,
          employees: employees,
          employee: employee ? employee : null,
        }));
      })
      .catch(error => handleError(error));
  };

  //TODO make processDefinitionName dynamic

  const getUserById = async (userId: string) => {
    const userData: any = await get(getUserDetails, userId);
    return userData.data.getUser;
  };

  const loadDocuments2 = async () => {
    if (!(props.processInstanceId && props.templateType && props.data.caseData.organisationId)) {
      throw new Error('missing data');
    }

    const rawProcessInstances = await queryHistoricProcessInstancesForDocumentFlows(
      props.processInstanceId,
      props.templateType,
    );

    const processInstances = rawProcessInstances.filter(p => p.processDefinitionName?.includes('document-flow'));

    const documentTasksPromises: Promise<unknown[]>[] = processInstances.map(p => {
      const documentIdVar = p.variables.find(v => v.name === 'documentId');
      if (documentIdVar?.value && typeof documentIdVar.value === 'string') {
        return Promise.all([
          get(getTemplateDocumentVersion, documentIdVar.value),
          queryHistoricTaskInstances(p.id),
          queryHistoricTaskInstancesByDocumentIdAndProcessInstanceId(documentIdVar.value, props.processInstanceId),
        ]);
      } else {
        throw new Error('process instance with id ' + p.id + 'missing var documentId');
      }
    });

    const promises: Promise<unknown>[] = documentTasksPromises;
    const res: unknown[] = await Promise.all(promises);

    const documentTasks: DocumentTask[] = [];
    for (const r of res) {
      const doc = (r as unknown[])[0] as { data: { getTemplateDocumentVersion: TemplateDocumentVersion } };
      const tasks = (r as unknown[])[1] as FlowableHistoricTask[];
      const documentCreationTasks = (r as unknown[])[2] as FlowableHistoricTask[];
      const userIds = new Set(
        tasks
          .concat(documentCreationTasks)
          .filter(t => !!t.assignee)
          .map(t => t.assignee),
      ) as Set<string>;

      const userPromises = Array.from(userIds).map(uid => getUserById(uid));
      const usersArrays = await Promise.all(userPromises);
      const usersMap: Record<string, User> = {};
      // @ts-ignore
      usersArrays.flat().forEach(u => (usersMap[u.id] = u));

      documentCreationTasks.forEach(dt => {
        const matchingDocumentProcessInstance = processInstances.find(p => {
          const processDocumentIdVar = p.variables.find(v => v.name === 'documentId');
          const taskDocumentIdVar = dt.variables.find(v => v.name === 'documentId');
          return !!(
            processDocumentIdVar?.value &&
            taskDocumentIdVar?.value &&
            processDocumentIdVar.value === taskDocumentIdVar.value
          );
        });
        if (!matchingDocumentProcessInstance) {
          throw new Error('no matchingDocumentProcessInstance found for document creation task');
        }
        dt.processInstanceId = matchingDocumentProcessInstance.id;
      });

      const completedTasks = tasks.filter(t => !!t.endTime && t.deleteReason === null);
      const activeTasks = tasks.filter(t => !t.endTime);

      completedTasks.concat(documentCreationTasks).forEach(t => {
        if (t.endTime) {
          let user;
          if (t.assignee) {
            user = usersMap[t.assignee];
          }
          const activeTaskDefinitionKeys = activeTasks.map((item: FlowableHistoricTask) =>
            item.taskDefinitionKey ? item.taskDefinitionKey : '',
          );
          documentTasks.push({
            processInstanceId: t.processInstanceId,
            doc: doc.data.getTemplateDocumentVersion,
            historicTask: t,
            activeTaskDefinitionKeys: activeTaskDefinitionKeys,
            user: user,
            isLatestTask: false,
          });
        }
      });
    }

    const sortedDocumentTasks: DocumentTask[] = documentTasks.sort(
      (a, b) => moment(a.historicTask.endTime).unix() - moment(b.historicTask.endTime).unix(),
    );

    if (sortedDocumentTasks.length) {
      sortedDocumentTasks[sortedDocumentTasks.length - 1].isLatestTask = true;
    }

    setState((oldState: DocumentVersionControlTableState) => ({
      ...oldState,
      isLoading: false,
      documentTasks: sortedDocumentTasks,
    }));
  };

  const approveDocument = async (documentTask: DocumentTask): Promise<void> => {
    if (currentUser) {
      setState((oldState: DocumentVersionControlTableState) => ({
        ...oldState,
        isLoading: true,
      }));
      const vars: FlowableVariable[] = [{ name: 'requestApproval', value: false }];

      await completeDocumentTask({
        documentId: documentTask.doc.id,
        taskDefinitionKey: 'approve-document-author',
        variables: vars,
        userId: currentUser.id,
        processInstanceId: documentTask.processInstanceId,
      })
        .then(loadDocuments2)
        .catch(error => {
          setState((oldState: DocumentVersionControlTableState) => ({
            ...oldState,
            isLoading: false,
          }));
          handleError(error);
        });
    } else {
      handleError(new Error('No current user'));
    }
  };

  const requestApproval = async (
    document: TemplateDocumentVersion,
    emailParams: EmailParamsV2,
    approverUserId: string,
    documentProcessInstanceId: string,
  ) => {
    const vars: FlowableVariable[] = [
      { name: 'emailParams', value: JSON.stringify(emailParams) },
      { name: 'requestApproval', value: true },
      { name: 'approverUserId', value: approverUserId },
    ];
    if (currentUser) {
      await completeDocumentEmailTask({
        documentId: document.id,
        taskDefinitionKey: 'request-approval',
        variables: vars,
        userId: currentUser.id,
        processInstanceId: documentProcessInstanceId,
      }).catch(error => handleError(error));
    } else {
      handleError(new Error('No current user'));
    }
  };

  const issueDocument = async (
    document: TemplateDocumentVersion,
    emailParams: EmailParamsV2,
    documentDeliveryMethod: DocumentDeliveryMethod,
    documentProcessInstanceId: string,
  ) => {
    if (currentUser) {
      const vars: FlowableVariable[] = [
        { name: 'emailParams', value: JSON.stringify(emailParams) },
        { name: 'documentDeliveryMethod', value: documentDeliveryMethod },
      ];
      await completeDocumentEmailTask({
        processInstanceId: documentProcessInstanceId,
        documentId: document.id,
        taskDefinitionKey: 'issue-document',
        variables: vars,
        userId: currentUser.id,
      })
        .then(async () => {
          props.data.flowableFunctions.loadSuspensionStatus(props.data.caseData.employeeId);
        })
        .catch(error => handleError(error));
    } else {
      handleError(new Error('No current user'));
    }
  };

  const clearModal = async () => {
    setState((oldState: DocumentVersionControlTableState) => ({ ...oldState, openModal: null }));
    if (props.data.organisation?.id) {
      loadDocuments2();
    }
  };

  const downloadDocumentV2 = async (bucketPath: string): Promise<void> => {
    await getFileURL(bucketPath)
      .then(async url => {
        window.open(url as string, '_self');
      })
      .catch(error => handleError(error));
  };

  const downloadDocument = async (document: TemplateDocumentVersion): Promise<void> => {
    if (currentUser) {
      await getFileURL(document.bucketPath)
        .then(async url => {
          window.open(url as string, '_self');
        })
        .catch(error => handleError(error));
    } else {
      handleError(new Error('No current user'));
    }
  };

  const renderModals = (): JSX.Element | null => {
    const currentDocumentTask = state.openModal?.documentTask;
    if (currentDocumentTask && state.employee) {
      if (state.openModal?.type === ModalType.ISSUE_DOCUMENT_TO_EMPLOYEE)
        return (
          <IssueLetterModal
            documentType={props.templateType}
            documentTask={currentDocumentTask}
            employee={state.employee}
            employees={state.employees}
            closeModal={(): Promise<void> => clearModal()}
            issueDocument={issueDocument}
            processInstanceId={props.processInstanceId}
            downloadDocument={downloadDocument}
            masterProcessInstanceId={props.data.masterProcessInstanceId}
            additionalAttachmentFormKeys={props.additionalAttachmentFormKeys}
            documentProcessInstanceId={state.openModal.documentTask.processInstanceId}
          />
        );
      else if (state.openModal?.type === ModalType.EMAIL_DRAFT_DOCUMENT_FOR_APPROVAL) {
        return (
          <EmailDraftModal
            documentTask={currentDocumentTask}
            employee={state.employee}
            employees={state.employees}
            closeModal={async (): Promise<void> => clearModal()}
            requestApproval={requestApproval}
            processInstanceId={props.processInstanceId}
            masterProcessInstanceId={props.data.masterProcessInstanceId}
            documentProcessInstanceId={state.openModal.documentTask.processInstanceId}
          />
        );
      } else return null;
    } else return null;
  };

  const renderButtonsForDownloadOnly = (documentTask: DocumentTask): JSX.Element => {
    return (
      <button
        className="btn mr-1 email-draft-letter-button text-center"
        onClick={async (): Promise<void> => {
          if (currentUser && documentTask.doc.uploadedFileBucketPath) {
            await downloadDocumentV2(documentTask.doc.uploadedFileBucketPath);
          } else {
            handleError(new Error('No current user or bucket path'));
          }
        }}
        title="Important: Please remember to hand the employee a copy of the document once signed by both parties."
        style={{ width: '100%', maxWidth: '150px' }}
      >
        DOWNLOAD
      </button>
    );
  };

  const renderButtonsForIssueDocument = (documentTask: DocumentTask): JSX.Element => {
    return (
      <>
        <button
          className="btn issue-suspension-letter-button"
          onClick={(): void => {
            const modal: TemplateDocumentModal = {
              type: ModalType.ISSUE_DOCUMENT_TO_EMPLOYEE,
              document: documentTask.doc,
              documentTask: documentTask,
            };
            setState((oldState: DocumentVersionControlTableState) => ({ ...oldState, openModal: modal }));
          }}
          style={{ width: '100%', maxWidth: '150px' }}
        >
          ISSUE LETTER
        </button>
      </>
    );
  };

  const renderButtonsForRequestApproval = (documentTask: DocumentTask): JSX.Element => {
    return (
      <span style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
        <button
          className="btn email-draft-letter-button text-center"
          onClick={(): void => {
            const modal: TemplateDocumentModal = {
              type: ModalType.EMAIL_DRAFT_DOCUMENT_FOR_APPROVAL,
              document: documentTask.doc,
              documentTask: documentTask,
            };
            setState((oldState: DocumentVersionControlTableState) => ({ ...oldState, openModal: modal }));
          }}
          style={{ width: '100%', maxWidth: '150px' }}
        >
          SEND FOR REVIEW
        </button>
        <button
          onClick={(): Promise<void> => approveDocument(documentTask)}
          className="btn issue-suspension-letter-button"
        >
          APPROVE FINAL LETTER
        </button>
      </span>
    );
  };

  const renderButtonsForApproveDocument = (docTask: DocumentTask): JSX.Element => {
    return (
      <span>
        <button onClick={(): Promise<void> => approveDocument(docTask)} className="btn issue-suspension-letter-button">
          APPROVE FINAL LETTER
        </button>
      </span>
    );
  };

  const uploadCallback = async (documentTask: DocumentTask, taskDefinitionKey: string, userId: string) => {
    completeDocumentTask({
      documentId: documentTask.doc.id,
      taskDefinitionKey: taskDefinitionKey,
      variables: [],
      userId: userId,
      processInstanceId: documentTask.processInstanceId,
    })
      .then(() => loadDocuments2())
      .catch(error => handleError(error));
  };

  const renderButtonsForUploadOnly = (docTask: DocumentTask): JSX.Element => {
    return (
      <>
        {currentUser && (
          <TableUploader
            alreadyDocument={false}
            processInstanceId={props.processInstanceId}
            documentId={docTask.doc.id}
            currentUser={currentUser}
            callback={() => uploadCallback(docTask, 'upload-signed-document-from-nominee', currentUser.id)}
          />
        )}
      </>
    );
  };

  const renderButtonsForUploadAndDownload = (documentTask: DocumentTask): JSX.Element => {
    return (
      <>
        <button
          className="btn mr-1 email-draft-letter-button text-center"
          onClick={async (): Promise<void> => {
            if (currentUser) {
              await downloadDocument(documentTask.doc);
            } else {
              handleError(new Error('No current user'));
            }
          }}
        >
          DOWNLOAD AGAIN
        </button>
        {currentUser && (
          <TableUploader
            alreadyDocument={false}
            processInstanceId={props.processInstanceId}
            documentId={documentTask.doc.id}
            currentUser={currentUser}
            callback={() => uploadCallback(documentTask, 'upload-signed-document-from-employee', currentUser.id)}
          />
        )}
      </>
    );
  };

  const taskDescriptions: Record<string, string> = {
    'upload-signed-document-from-nominee': 'Document Uploaded',
    'upload-signed-document-from-employee': 'Document Uploaded',
    'approve-document-author': 'Approved',
    'approve-document-approver': 'Approved',
    'request-approval': 'Approval Requested',
    'sign-document': 'Digitally signed by employee',
    'confirm-receipt': 'Receipt confirmed by Nominee',
  };

  const getTaskDescription = (task: FlowableHistoricTask): string => {
    const taskDefinitionKey = task.taskDefinitionKey;
    if (taskDefinitionKey) {
      if (taskDescriptions[taskDefinitionKey]) {
        return taskDescriptions[taskDefinitionKey];
      } else if (task.taskDefinitionKey === 'generic-document-creation-task') {
        return 'New Version';
      } else if (taskDefinitionKey === 'issue-document') {
        const deliveryMethod: FlowableVariable | undefined = task.variables.find(
          (item: FlowableVariable) => item.name === 'documentDeliveryMethod',
        );
        if (deliveryMethod) {
          if (deliveryMethod.value === DocumentDeliveryMethod.DIRECT_EMAIL) {
            return 'Emailed to Employee';
          } else if (deliveryMethod.value === DocumentDeliveryMethod.NOMINATE_EMPLOYEE_TO_DELIVER) {
            return 'Emailed to Nominee';
          } else if (deliveryMethod.value === DocumentDeliveryMethod.SIGN_DIGITALLY) {
            return 'Awaiting signature';
          } else if (deliveryMethod.value === DocumentDeliveryMethod.PRINT_AND_DELIVER) {
            return 'Downloaded';
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else return '';
    } else return '';
  };

  const renderButtonsForTasks = (documentTask: DocumentTask): JSX.Element => {
    // Physical document has been uploaded:
    if (documentTask.historicTask.taskDefinitionKey === 'upload-signed-document-from-nominee') {
      return renderButtonsForDownloadOnly(documentTask);

      // Physical document has been uploaded:
    } else if (documentTask.historicTask.taskDefinitionKey === 'upload-signed-document-from-employee') {
      return renderButtonsForDownloadOnly(documentTask);

      // Digital signature has been captured:
    } else if (documentTask.historicTask.taskDefinitionKey === 'sign-document') {
      return renderButtonsForDownloadOnly(documentTask);
    } else if (documentTask.isLatestTask) {
      // Approve document task and request approval tasks are pending:
      if (
        documentTask.activeTaskDefinitionKeys.includes('approve-document-author') &&
        documentTask.activeTaskDefinitionKeys.includes('request-approval')
      ) {
        return renderButtonsForRequestApproval(documentTask);

        // Approve document task is pending:
      } else if (
        documentTask.activeTaskDefinitionKeys.includes('approve-document-author') ||
        documentTask.activeTaskDefinitionKeys.includes('approve-document-approver')
      ) {
        return renderButtonsForApproveDocument(documentTask);

        // Issue document task is pending:
      } else if (documentTask.activeTaskDefinitionKeys.includes('issue-document')) {
        return renderButtonsForIssueDocument(documentTask);

        // Upload signed document task is pending:
      } else if (documentTask.activeTaskDefinitionKeys.includes('upload-signed-document-from-employee')) {
        return renderButtonsForUploadAndDownload(documentTask);

        //  Upload signed document from nominee task is pending:
      } else if (documentTask.activeTaskDefinitionKeys.includes('upload-signed-document-from-nominee')) {
        return renderButtonsForUploadOnly(documentTask);
      } else if (documentTask.activeTaskDefinitionKeys.includes('sign-document')) {
        return (
          <a
            href={`https:/labourteq.com/sign-document/${documentTask.processInstanceId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn mr-1 email-draft-letter-button text-center"
            style={{display: 'none'}}
          >
            LET EMPLOYEE SIGN
          </a>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (currentUser?.organisationId) {
      loadEmployeesAndConfig(currentUser.organisationId);
    }
    loadDocuments2();
    const refreshDocumentControl = setInterval(() => {
      loadDocuments2();
    }, 1000 * 30);
    return () => clearInterval(refreshDocumentControl);
  }, []);

  const getStatusFlowable = (task: FlowableHistoricTask): string => {
    if (task.taskDefinitionKey === 'generic-document-creation-task') {
      return 'New version';
    } else if (task.taskDefinitionKey === 'request-approval') {
      return 'Approval requested';
    } else if (
      task.taskDefinitionKey === 'approve-document-approver' ||
      task.taskDefinitionKey === 'approve-document-author'
    ) {
      return 'Approved';
    } else if (task.taskDefinitionKey === 'issue-document') {
      const deliveryMethod: FlowableVariable | undefined = task.variables.find(
        (item: FlowableVariable) => item.name === 'documentDeliveryMethod',
      );
      if (deliveryMethod) {
        if (
          deliveryMethod.value === DocumentDeliveryMethod.DIRECT_EMAIL ||
          deliveryMethod.value === DocumentDeliveryMethod.SIGN_DIGITALLY
        ) {
          return 'Awaiting Digital Signature';
        } else if (deliveryMethod.value === DocumentDeliveryMethod.NOMINATE_EMPLOYEE_TO_DELIVER) {
          return 'Emailed to Nominee for Delivery';
        } else if (deliveryMethod.value === DocumentDeliveryMethod.PRINT_AND_DELIVER) {
          return 'Awaiting Upload';
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else if (
      task.taskDefinitionKey === 'upload-signed-document-from-employee' ||
      task.taskDefinitionKey === 'upload-signed-document-from-nominee'
    ) {
      return 'Uploaded';
    } else if (task.taskDefinitionKey === 'sign-document') {
      return 'Digitally signed';
    } else if (task.taskDefinitionKey === 'confirm-receipt') {
      return 'Received by nominee';
    } else {
      return '';
    }
  };

  const renderRow = (documentTask: DocumentTask, key: number): JSX.Element => {
    return (
      <tr key={key}>
        <td key="1">{moment(documentTask.historicTask.endTime).format('DD/MM/YYYY')}</td>
        <td key="2">{documentTask.doc.version}</td>
        <td key="3">{getTaskDescription(documentTask.historicTask)}</td>
        <td key="4">
          {documentTask.user
            ? toTitleCase(documentTask.user.firstName + ' ' + documentTask.user.lastName, ' ')
            : 'External User'}
        </td>
        <td key="5">{getStatusFlowable(documentTask.historicTask)}</td>
        <td key="6">
          <div
            className={
              'document-status-circle' +
              ' ' +
              (documentTask.historicTask.taskDefinitionKey === 'approve-document-author' ||
              documentTask.historicTask.taskDefinitionKey === 'approve-document-approver' ||
              documentTask.historicTask.taskDefinitionKey === 'sign-document'
                ? 'document-status-circle-approved'
                : 'document-status-circle-requires-revision')
            }
          />
        </td>
        <td key="7" style={{ minWidth: '135px' }}>
          <Button
            className="view-document-button"
            style={{ minWidth: '30px', maxWidth: '30px', borderRadius: '2px' }}
            onClick={(): void =>
              props.data.flowableFunctions.setDocument(
                documentTask.doc.id,
                true,
                null,
                documentTask.historicTask.taskDefinitionKey === 'sign-document',
                // documentEvent.event.type === AuditLogEventType.DOCUMENT_SIGNED,
              )
            }
          >
            <i className="fa fa-eye" />
          </Button>
          {/*{documentEvent.isLatest && documentEvent.canEdit && (*/}
          <Button
            className="edit-document-button"
            style={{ minWidth: '30px', maxWidth: '30px', borderRadius: '2px' }}
            onClick={(): void => props.data.flowableFunctions.setDocument(documentTask.doc.id, false, null, false)}
          >
            <i className="fa fa-pen" />
          </Button>
        </td>
        <td key="8" style={{ display: 'flex', justifyContent: 'flex-start', height: 68 }}>
          {renderButtonsForTasks(documentTask)}
        </td>
      </tr>
    );
  };

  return state.documentTasks.length ? (
    <>
      {renderModals()}
      <h4 className="text-primary">Document Version Control</h4>
      {!state.isLoading ? (
        <BootStrapTable>
          <thead>
            <tr>
              {columnHeadings.map((heading, index) => (
                <th className="text-blue font-weight-bold" key={index}>
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{state.documentTasks.map((item: DocumentTask, index: number) => renderRow(item, index))}</tbody>
        </BootStrapTable>
      ) : (
        <div className="d-flex justify-content-center align-items-center">{state.isLoading && <Loader />}</div>
      )}
      {props.templateType && (
        <EmailTrackerTable data={props.data} templateType={props.templateType} showSystemEmailOptions={true} />
      )}
    </>
  ) : (
    <></>
  );
};
