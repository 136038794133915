import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import RecordPIPProgressMeetingForm from './RecordPIPProgressMeetingForm';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import Popup from '../../../../components/Popup/Popup.component';

export interface PipProgressMeeting {
  meetingId: string;
  pipProgressMeetingDate: string;
  pipProgressMeetingTime: string;
  pipProgressMeetingLocation: string;
  pipProgressMeetingAttendees: any[] | null;
  sessionCompleted: boolean;
}

const blankPerformanceShortfall = [
  {
    shortfallDescription: '',
    date: '',
    performanceGoal: '',
    qualityStandard: '',
    deadline: '',
    assistanceRequiredFromEmployer: '',
    actionsToBeTakenByEmployee: '',
    pipProgressUpdates: null,
  },
];

const RecordPIPProgressMeeting: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const { pipType } = caseData;
  const fields = {
    performanceShortfalls: 'array',
    pipProgressMeetings: 'array',
    pipStartDate: 'string',
    pipEndDate: 'string',
    sessionsCompleted: 'boolean',
  };

  const [data, setData] = useState(() => getFormDataFromCaseData(fields, caseData));

  useEffect(() => {
    if (!data.performanceShortfalls) {
      setData(data => ({ ...data, performanceShortfalls: blankPerformanceShortfall }));
    } else {
      const shortfalls = data.performanceShortfalls.map((shortfall: { [key: string]: any }) => {
        return {
          ...shortfall,
          performanceGoal: shortfall.performanceGoal ? shortfall.performanceGoal : '',
          qualityStandard: shortfall.qualityStandard ? shortfall.qualityStandard : '',
          deadline: shortfall.deadline ? shortfall.deadline : '',
          assistanceRequiredFromEmployer: shortfall.assistanceRequiredFromEmployer
            ? shortfall.assistanceRequiredFromEmployer
            : '',
          actionsToBeTakenByEmployee: shortfall.actionsToBeTakenByEmployee ? shortfall.actionsToBeTakenByEmployee : '',
          pipProgressUpdates: shortfall.pipProgressUpdates ? shortfall.pipProgressUpdates : null,
        };
      });
      setData(data => ({ ...data, performanceShortfalls: shortfalls }));
    }
  }, [JSON.stringify(data)]);

  const canProceed = async (values: FormikValues): Promise<boolean> => {
    const hasInCompleteSessions = !!values.pipProgressMeetings.filter(
      (session: PipProgressMeeting) => !session.sessionCompleted,
    ).length;

    const message = hasInCompleteSessions
      ? 'Are you sure you want to continue without completing all your scheduled meetings?'
      : 'Are you sure you want to continue?';

    return await Popup.confirm(
      'Complete PIP Progress Meetings',
      `${message} Note that this decision cannot be reversed.`,
    );
  };

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    formValues.pipProgressMeetings = JSON.stringify(formValues.pipProgressMeetings);
    formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">
        {pipType === 'EXTENDED_PIP' ? 'Record Extended PIP Progress Meetings' : 'Record PIP Progress Meetings'}
      </h4>
      <Formik initialValues={data} onSubmit={submitValues} enableReinitialize>
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <RecordPIPProgressMeetingForm processInstanceId={props.data.processInstanceId} pipType={pipType} />
            <WorkFlowFooter
              hideSaveAndCloseButton={true}
              data={props.data}
              onNext={async (): Promise<void> => {
                if (!values.sessionsCompleted) if (!(await canProceed(values))) return;
                values.sessionsCompleted = true;
                handleSubmit();
              }}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={() => props.data.flowableFunctions.onSaveAndClose(values)}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default RecordPIPProgressMeeting;
