import { CaseType, EmailAddressType, InvestigatorInput, TemplateType } from '../../API';
import { FormikValues } from 'formik';
import { EmailAddress, Employee, Organisation } from '../../models';
import { FlowableVariable } from '../../utils/flowable/flowable-types';
import { getComponentByFormKey } from './getComponentByFormKey';
import { TaskData } from './WorkflowContainer';
import { TaskConfig } from '../../configs/task-configs/task-config-types';

export interface FormProps extends WorkflowComponentProps {
  getFormValuesForSubmission: (values: FormikValues) => { [key: string]: any };
}

export interface WorkflowComponentProps {
  data: Data;
  templateType?: TemplateType;
}

export interface PipProgressUpdate {
  reviewPeriod: string;
  progressRemarks: string;
  progressStatus: string;
  progressMeetingId: string;
}

export interface CounsellingProgressUpdate {
  sessionId: string,
  update: string;
  date: string;
}

export interface Data {
  processInstanceId: string;
  flowableFunctions: FlowableFunctions;
  employeeFullName: string;
  employeeNumber: string;
  employeeWorkEmailAddresses: string[];
  caseData: CaseData;
  organisation: Organisation | null;
  caseOwnerDetails: CaseOwnerDetails | null;
  masterProcessInstanceId: string;
  employee: Employee;
  showFirstTaskWarning: boolean;
  disableBackButton: boolean;
  taskData: TaskData;
  taskConfig: TaskConfig | null;
  caseType: CaseType | null;
  disableFooter?: boolean;
}

export interface CaseOwnerDetails {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface FlowableFunctions {
  completeTask: (flowableVariables?: FlowableVariable[]) => void;
  onNext: (formData?: any, closeCase?: boolean) => void;
  onSaveAndClose: (formData?: any) => void;
  onUpdateTask?: (variableName: string, values?: any) => void;
  goBack: (formData?: any) => void;
  formKey: string;
  formData: any | null;
  setDocument: (
    documentId: string | null,
    readOnly: boolean,
    templateType: TemplateType | null,
    showSignedCopy: boolean,
    tableValues?: any,
  ) => void;
  clearDocument: () => void;
  onOpenNewDocument: (
    formValues: FormikValues,
    formFields: Record<string, any>,
    documentType: TemplateType,
  ) => Promise<void>;
  closeCase: () => void;
  loadSuspensionStatus: (employeeId: string) => void;
}

export interface CachedFormData {
  taskDefinitionKey: string;
  data: FormikValues;
}

export interface CaseData extends Record<string, unknown> {
  documents: any[];
  caseOwnerEmail: string;
  currentTaskDefinitionKey: string;
  cachedFormData: CachedFormData;
  caseStatus: string | null;

  // Basic case data:
  id?: string | null;
  caseNumber?: string;
  employeeId: string;
  caseOwnerUserId: string;
  organisationId: string;
  issueDescription?: string;
  incidents: CaseIncident[];
  performanceShortfalls: CasePerformanceShortfall[];
  sanction?: string | null;
  expiryDate?: string | null;
  investigators?: Array<InvestigatorInput | null> | null;
  isDeviationFromCode: boolean;
  processInstanceId?: string | null;
  warningValidityPeriod: number;
  correctiveActions: string[];
  processType: string;
  shouldSuspendPendingInvestigation: boolean;
  isFurtherInvestigationRequired: boolean;
  investigationReportDocuments: BucketFile[];
  isAppeal: boolean;
  motivationForDisciplinaryAction: string;
  appealReason: string;
  investigatorStepNumber: number;
  chairpersonStepNumber: number;
  appealChairpersonStepNumber: number;
  isCaseClosed: boolean;
  caseClosedPath: string;

  // Suspension discussion data:
  suspensionDiscussionDate: string;
  suspensionDiscussionTime: string;
  suspensionDiscussionAttendees: any[];
  suspensionDiscussionSummary: string;

  // Suspension decision data:
  suspensionDecision: boolean;
  suspensionDecisionReason: string;
  suspensionDecisionEffectiveDate: string;

  // Suspension dates:
  suspensionStartDate: string;
  suspensionEndDate: string;
  returnToWorkDate: string;
  suspensionPeriod: string;

  // Disciplinary discussion data:
  disciplinaryDiscussionDate: string;
  disciplinaryDiscussionTime: string;
  disciplinaryDiscussionLocation: string;
  disciplinaryDiscussionAttendees: any[];
  disciplinaryDiscussionSummary: string;
  disciplinaryDiscussionDocuments: BucketFile;
  disciplinaryDiscussionDecision: string;
  disciplinaryDecisionReason: string;
  warningExpiryDate: string;

  // Hearing data:

  initial_hearing_suspendPendingHearing: boolean;
  initial_hearing_employerRepresentativeType: string;
  initial_hearing_employerRepresentativeFirstName: string;
  initial_hearing_employerRepresentativeLastName: string;
  initial_hearing_employerRepresentativeId: string;
  initial_hearing_employerRepresentativeEmailAddress: string;
  initial_hearing_hearingDate: string;
  initial_hearing_hearingTime: string;
  initial_hearing_hearingLocation: string;
  initial_hearing_chairpersonFirstName: string;
  initial_hearing_chairpersonLastName: string;
  initial_hearing_chairpersonEmailAddress: string;
  initial_hearing_chairpersonId: string;
  initial_hearing_hearingWitnesses: Witness[];
  initial_hearing_isEmployerRepresentativePresent: boolean;
  initial_hearing_isChairpersonPresent: boolean;
  initial_hearing_isEmployeePresent: boolean;
  initial_hearing_hearingOtherAttendees: string | null;
  initial_hearing_allowExternalRepresentation: boolean;
  initial_hearing_externalRepresentationApplicationEmployeeSubmissionDocuments: BucketFile[];
  initial_hearing_externalRepresentationApplicationEmployerResponseDocuments: BucketFile[];
  initial_hearing_externalRepresentationApplicationRulingDocuments: BucketFile[];
  initial_hearing_minutesOrRecordingsFiles: BucketFile[];
  initial_hearing_documentaryEvidenceFiles: BucketFile[];
  initial_hearing_chairpersonsRulingDocuments: BucketFile[];
  overallSanction: string;

  // Appeal Hearing data:

  appeal_hearing_suspendPendingHearing: boolean;
  appeal_hearing_employerRepresentativeType: string;
  appeal_hearing_employerRepresentativeFirstName: string;
  appeal_hearing_employerRepresentativeLastName: string;
  appeal_hearing_employerRepresentativeId: string;
  appeal_hearing_employerRepresentativeEmailAddress: string;
  appeal_hearing_hearingDate: string;
  appeal_hearing_hearingTime: string;
  appeal_hearing_hearingLocation: string;
  appeal_hearing_chairpersonFirstName: string;
  appeal_hearing_chairpersonLastName: string;
  appeal_hearing_chairpersonEmailAddress: string;
  appeal_hearing_chairpersonId: string;
  appeal_hearing_hearingWitnesses: Witness[];
  appeal_hearing_isEmployerRepresentativePresent: boolean;
  appeal_hearing_isChairpersonPresent: boolean;
  appeal_hearing_isEmployeePresent: boolean;
  appeal_hearing_hearingOtherAttendees: string | null;
  appeal_hearing_allowExternalRepresentation: boolean;
  appeal_hearing_externalRepresentationApplicationEmployeeSubmissionDocuments: BucketFile[];
  appeal_hearing_externalRepresentationApplicationEmployerResponseDocuments: BucketFile[];
  appeal_hearing_externalRepresentationApplicationRulingDocuments: BucketFile[];
  appeal_hearing_minutesOrRecordingsFiles: BucketFile[];
  appeal_hearing_documentaryEvidenceFiles: BucketFile[];
  appeal_hearing_chairpersonsRulingDocuments: BucketFile[];
  revisedOverallSanction: string;
  initial_hearing_deliveredSanctionOutcomeLetter: boolean;
  initial_hearing_acceptedDemotion: string;
  initial_hearing_acceptedSuspensionWithoutPay: string;

  // Dismissal data:
  dismissalDate: string;
  lastRemunerationDate: string;
  contactPersonInCaseOfCCMA: string;
  physicalAddress: string;
  emailAddress: string;

  // Demotion data:
  physicalExitDate: string;
  demotionNewJobTitleId: string;
  demotionNewJobTitle: string;
  demotionNewJobLevelId: string;
  demotionNewJobLevel: string;
  demotionNewDepartmentId: string;
  demotionNewDepartment: string;
  demotionNewRemunerationPackage: string;
  demotionNewTerms: string;
  demotionDate: string;

  chairpersonsRulingDocuments: BucketFile[];

  // PIP data:
  pipImpactingFactors: [];
  period: string;
  pipStartDate: string;
  pipEndDate: string;
  pipDuration: string;
  appealProcessBackground: string;
  appellantArgumentSummary: string;
  employerRepArgumentSummary: string;
  newEvidenceSummary: string;
  legalPrinciplesApplied: string;
  conclusionsReached: string;
  appealOutcomes: string;
  guiltFindings: string;
  overturnedGuiltFindings: string;
  sanctionFindings: string;
  overturnedSanctionFindings: string;
  objectives: [];
  groundsForAppeal: string;
  expectedOutcome: string;
  qualityStandard: string;
  hasCompletedFinalWrittenWarningPIP: boolean;
  hasCompletedExtendedPIP: boolean;
  hasCompletedBasicPIP: boolean;
  pipType: string;

  documentCreationTaskId?: string;
}

export interface CaseIncident {
  summaryOfFacts: string;
  date: string;
  time: string;
  location: string;
  witnesses: Witness[];
  complainants: [];
  transgression: string;
  transgressionCategory: string;
  frequency: string;
  potentialSanction: string;
  process: string;
  action: string;
  correctiveAction: string;
  initial_hearing_chairpersonsFindingOnGuilt: string | null;
  initial_hearing_chairpersonsSanction: string | null;
  didEmployeeAppealRuling: string;
  didEmployeeAppealSanction: string;
  sanctionConfirmed: boolean;
  appeal_hearing_findingsOnGuiltConfirmed: boolean;
  reasonForOverturningSanction: string | null;
  appeal_hearing_chairpersonsFinding: string | null;
  appeal_hearing_chairpersonsSanction: string | null;
  appeal_hearing_chairpersonConfirmedSanction: string | null;
  reason_hc_deviation_motivation_ruling: string | null;
  reason_hc_deviation_motivation_sanction: string | null;
  initial_hearing_hcApproversFindingOnGuilt: string | null;
  initial_hearing_hcApproversSanction: string | null;
}

export interface CasePerformanceShortfall {
  initial_hearing_hcApproversFindingOnGuilt: string;
  shortfallDescription: string;
  date: string;
  performanceGoal: string;
  deadline: string;
  qualityStandard: string;
  assistanceRequiredFromEmployer: string;
  actionsToBeTakenByEmployee: string;
  counsellingProgressUpdates: CounsellingProgressUpdate[];
  pipProgressUpdates: PipProgressUpdate[];
  documents: BucketFile[];
  correctiveAction: string;
  initial_hearing_chairpersonsFindingOnGuilt: string;
  initial_hearing_chairpersonsSanction: string;
  didEmployeeAppealRuling: string;
  didEmployeeAppealSanction: string;
  appeal_hearing_chairpersonsFinding: string;
  appeal_hearing_chairpersonsSanction: string;
  sanctionConfirmed: boolean;
  appeal_hearing_findingsOnGuiltConfirmed: boolean;
  appeal_hearing_chairpersonConfirmedSanction: string;
  reasonForOverturningSanction: string;
}

export interface Witness {
  firstName: string;
  lastName: string;
  emailAddress: string;
  employeeId?: string;
  presentAtHearing?: boolean;
}

export interface BucketFile {
  fileName: string;
  key: string;
  url: string;
}

export const renderComponentByFormKey = (
  formKey: string,
  flowableFunctions: FlowableFunctions,
  employee: Employee,
  caseData: CaseData,
  processInstanceId: string,
  organisation: Organisation | null,
  caseOwnerDetails: CaseOwnerDetails | null,
  masterProcessInstanceId: string,
  showFirstTaskWarning: boolean,
  disableBackButton: boolean,
  taskData: TaskData,
  taskConfig: TaskConfig | null,
  caseType: CaseType | null,
  disableFooter: boolean,
): JSX.Element | null => {
  const getEmployeeWorkEmailAddresses = (employee: Employee): string[] => {
    const workEmailAddresses: string[] = [];
    if (employee?.emails)
      employee.emails.forEach((item: EmailAddress | null) => {
        if (item && item.emailAddressType === EmailAddressType.WORK) workEmailAddresses.push(item.address);
      });
    return workEmailAddresses;
  };

  const employeeWorkEmailAddresses = getEmployeeWorkEmailAddresses(employee);

  const data: Data = {
    flowableFunctions: flowableFunctions,
    caseData: caseData,
    processInstanceId: processInstanceId,
    employeeFullName: employee?.firstName + ' ' + employee?.lastName,
    employeeWorkEmailAddresses: employeeWorkEmailAddresses,
    employeeNumber: employee?.employeeNumber ? employee?.employeeNumber : '',
    organisation: organisation,
    caseOwnerDetails: caseOwnerDetails,
    masterProcessInstanceId: masterProcessInstanceId,
    disableFooter: disableFooter,
    employee,
    showFirstTaskWarning: showFirstTaskWarning,
    disableBackButton: disableBackButton,
    caseType: caseType,
    taskData,
    taskConfig,
  };

  return getComponentByFormKey(formKey, data);
};

const defaultItemValuesByType: { [key: string]: false | null | undefined } = {
  boolean: false,
  map: null,
  array: null,
};

export const getFormDataFromCaseData = (
  fields: Record<string, any>,
  caseData: CaseData,
  noCacheCheck?: boolean,
): FormikValues => {
  // First check for cached form data (not submitted form data)
  console.log({ caseData });
  if (
    caseData.cachedFormData?.data &&
    caseData.cachedFormData.taskDefinitionKey === caseData.currentTaskDefinitionKey &&
    !noCacheCheck
  ) {
    return caseData.cachedFormData.data;
  }

  const formData: Record<string, any> = {};
  Object.keys(fields).forEach((fieldName: string) => {
    const fieldType: string = fields[fieldName];

    let itemValue = (caseData as any)[fieldName];
    if (itemValue === undefined) {
      let defaultValue: any = defaultItemValuesByType[fieldType];
      if (defaultValue === undefined) {
        defaultValue = '';
      }
      itemValue = defaultValue;
    }
    formData[fieldName] = itemValue;
  });
  console.log({ formData });
  return formData;
};

export const filterFormData = (formValues: FormikValues, formFields: Record<string, any>): Record<string, any> => {
  const values: Record<string, any> = {};
  Object.keys(formFields).forEach((fieldName: string) => {
    const itemValue = formValues[fieldName];
    if (itemValue !== undefined) {
      values[fieldName] = itemValue;
    } else {
      throw new Error('form data contains an undefined value: ' + fieldName);
    }
  });
  return values;
};
