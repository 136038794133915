import { CaseData, CaseIncident } from '../../WorkflowContainer/workflow-utils';
import { DocumentJSONContent } from '../DocumentEditor';
import { toTitleCase } from '../../../utils/string-utils';
import { formatAppealFinding } from '../common-document-utils';

export const warningLetterDiscussionFields = [
  'warningValidityPeriod',
  'disciplinaryDiscussionDate',
  'transgressions',
  'correctiveActions',
];
export const warningLetterHearingFields = [
  'warningValidityPeriod',
  'initial_hearing_hearingDate',
  'charges_guilty',
  'correctiveActions',
  'other',
];
export const appealOutcomeLetterFields = [
  'appeal_hearing_hearingDate',
  'appealSanctionOutcomes',
  'appealFindingOutcomes',
  'appealOutcomeText',
  'appealReason',
  'other',
];
export const noticeOfHearingFields = [
  'initial_hearing_hearingDate',
  'initial_hearing_hearingLocation',
  'initial_hearing_hearingTime',
  'initial_hearing_chairpersonFirstName',
  'initial_hearing_chairpersonLastName',
  'charges',
  'allegations',
  'other',
];
export const noticeOfAppealHearingFields = [
  'appeal_hearing_hearingDate',
  'appeal_hearing_hearingLocation',
  'appeal_hearing_hearingTime',
  'appeal_hearing_chairpersonFirstName',
  'appeal_hearing_chairpersonLastName',
  'allegations',
];
export const summaryDismissalLetterFields = [
  'initial_hearing_hearingDate',
  'initial_hearing_hearingLocation',
  'charges_guilty',
  'finalRemunerationDate',
  'dismissalDate',
  'contactName',
  'contactEmail',
  'contactAddress',
  'other',
];
export const demotionLetterFields = [
  'demotionNewJobTitle',
  'demotionNewJobLevel',
  'demotionNewDepartment',
  'demotionNewRemunerationPackage',
  'demotionNewTerms',
  'demotionDate',
  'initial_hearing_hearingDate',
  'initial_hearing_hearingLocation',
  'charges_guilty',
  'finalRemunerationDate',
  'dismissalDate',
  'contactName',
  'contactEmail',
  'contactAddress',
  'other',
];
export const suspensionWithoutPayLetterFields = [
  'initial_hearing_hearingDate',
  'initial_hearing_hearingLocation',
  'charges_guilty',
  'finalRemunerationDate',
  'dismissalDate',
  'contactName',
  'contactEmail',
  'contactAddress',
  'suspensionStartDate',
  'suspensionEndDate',
  'returnToWorkDate',
  'suspensionPeriod',
  'other',
];
export const dismissalWithNoticeLetterFields = [
  'initial_hearing_hearingDate',
  'initial_hearing_hearingLocation',
  'charges_guilty',
  'finalRemunerationDate',
  'dismissalDate',
  'contactName',
  'contactEmail',
  'contactAddress',
  'other',
];

export const suspensionLetterFields: string[] = ['other', 'allegations'];

const getAppealReasonText = (caseIncidents: CaseIncident[]): string => {
  const incidents = caseIncidents.filter(
    (item: CaseIncident) => item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
  );
  if (incidents.filter((item: CaseIncident) => item.didEmployeeAppealRuling === 'YES').length) {
    return 'findings of guilt';
  } else if (incidents.filter((item: CaseIncident) => item.didEmployeeAppealSanction === 'YES').length) {
    return 'sanction';
  } else return '';
};

const getAppealOutcomeText = (caseData: CaseData): string => {
  const incidents = caseData.incidents.filter(
    (item: CaseIncident) => item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
  );
  if (
    incidents.filter((item: CaseIncident) => item.appeal_hearing_chairpersonsFinding === 'OVERTURN').length ===
    incidents.length
  ) {
    return 'All charges are dropped, and the disciplinary process is concluded.';
  } else if (caseData.overallSanction === caseData.revisedOverallSanction) {
    return 'The findings of the original disciplinary hearing remain as is.';
  } else if (
    !incidents.filter(
      (item: CaseIncident) =>
        item.appeal_hearing_chairpersonsFinding === 'OVERTURN' ||
        item.appeal_hearing_chairpersonsSanction === 'OVERTURN',
    ).length
  ) {
    return 'The findings of the original disciplinary hearing remain as is.';
  } else if (caseData.revisedOverallSanction && caseData.revisedOverallSanction !== caseData.overallSanction) {
    return (
      'The overall sanction is changed from ' +
      toTitleCase(caseData.overallSanction, '_') +
      ' to ' +
      toTitleCase(caseData.revisedOverallSanction, '_') +
      '.'
    );
  } else return '';
};

const getRelevantValues = (caseData: CaseData): { [key: string]: string | string[] } => {
  const appealOutcomeText = getAppealOutcomeText(caseData);
  const appealReasonText = getAppealReasonText(caseData.incidents);
  const transgressions = caseData.incidents.map(item => item.transgression);
  const correctiveActions = caseData.incidents
    ? caseData.incidents.map((item: CaseIncident) => (item.correctiveAction ? item.correctiveAction : ''))
    : [''];
  return {
    appealOutcomeText,
    appealReasonText,
    transgressions,
    correctiveActions,
  };
};

export const prepareFieldValuesMisconduct = (caseData: CaseData): DocumentJSONContent => {
  const { appealOutcomeText, appealReasonText, transgressions, correctiveActions } = getRelevantValues(caseData);
  return {
    other: {
      label: 'Additional Comments',
      type: 'textarea',
      value: '',
    },
    warningValidityPeriod: {
      label: 'Warning Period',
      type: 'number',
      value: caseData.warningValidityPeriod ? caseData.warningValidityPeriod : '',
    },
    suspensionDiscussionDate: {
      label: 'Discussion Date',
      type: 'date',
      value: caseData.suspensionDiscussionDate,
    },
    disciplinaryDiscussionDate: {
      label: 'Discussion Date',
      type: 'date',
      value: caseData.disciplinaryDiscussionDate ? caseData.disciplinaryDiscussionDate : '',
    },
    allegations: {
      label: 'Summary of Allegations',
      type: 'array',
      value: caseData.incidents ? caseData.incidents.map((item: CaseIncident) => item.summaryOfFacts) : [],
    },
    appealFindingOutcomes: {
      label: null,
      type: 'array',
      value: caseData.incidents
        ? caseData.incidents
            .filter((item: CaseIncident) => item.didEmployeeAppealRuling)
            .map(
              (item: CaseIncident) =>
                item.transgression + ': ' + formatAppealFinding(item.appeal_hearing_chairpersonsFinding!),
            )
        : [],
    },
    appealSanctionOutcomes: {
      label: null,
      type: 'array',
      value: caseData.incidents
        ? caseData.incidents
            .filter((item: CaseIncident) => item.didEmployeeAppealSanction)
            .map(
              (item: CaseIncident) =>
                item.transgression + ': ' + formatAppealFinding(item.appeal_hearing_chairpersonsSanction!),
            )
        : [],
    },
    appealOutcomeText: {
      label: null,
      type: 'text',
      value: appealOutcomeText,
    },
    appealReason: {
      label: null,
      type: 'text',
      value: appealReasonText,
    },
    transgressions: {
      label: 'Transgressions',
      type: 'array',
      value: transgressions,
    },
    charges: {
      label: 'Charges',
      type: 'array',
      value: transgressions,
    },
    charges_guilty: {
      label: 'Charges',
      type: 'array',
      value: caseData.incidents
        .filter((item: CaseIncident) => {
          return caseData.isAppeal
            ? item.appeal_hearing_chairpersonsFinding === 'CONFIRM' ||
                (item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY' && item.didEmployeeAppealRuling === 'NO')
            : item.initial_hearing_hcApproversFindingOnGuilt
            ? item.initial_hearing_hcApproversFindingOnGuilt === 'GUILTY'
            : item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY';
        })
        .map((item: CaseIncident) => item.transgression),
    },
    correctiveActions: {
      label: 'Corrective Actions',
      type: 'array',
      value: correctiveActions,
    },
    initial_hearing_chairpersonFirstName: {
      label: "Chairperson's First Name",
      type: 'text',
      value: caseData.initial_hearing_chairpersonFirstName
        ? toTitleCase(caseData.initial_hearing_chairpersonFirstName, ' ')
        : '',
    },
    initial_hearing_chairpersonLastName: {
      label: "Chairperson's Last Name",
      type: 'text',
      value: caseData.initial_hearing_chairpersonLastName
        ? toTitleCase(caseData.initial_hearing_chairpersonLastName, ' ')
        : '',
    },
    initial_hearing_hearingTime: {
      label: 'Time',
      type: 'time',
      value: caseData.initial_hearing_hearingTime ? caseData.initial_hearing_hearingTime : '',
    },
    initial_hearing_hearingDate: {
      label: 'Hearing Date',
      type: 'date',
      value: caseData.initial_hearing_hearingDate ? caseData.initial_hearing_hearingDate : '',
    },
    appeal_hearing_hearingDate: {
      label: 'Appeal Hearing Date',
      type: 'date',
      value: caseData.appeal_hearing_hearingDate ? caseData.appeal_hearing_hearingDate : '',
    },
    appeal_hearing_hearingLocation: {
      label: 'Appeal Hearing Location',
      type: 'text',
      value: caseData.appeal_hearing_hearingLocation ? caseData.appeal_hearing_hearingLocation : '',
    },
    appeal_hearing_hearingTime: {
      label: 'Appeal Hearing Time',
      type: 'time',
      value: caseData.appeal_hearing_hearingTime ? caseData.appeal_hearing_hearingTime : '',
    },
    appeal_hearing_chairpersonFirstName: {
      label: 'Appeal Hearing Chairperson First Name',
      type: 'text',
      value: caseData.appeal_hearing_chairpersonFirstName
        ? toTitleCase(caseData.appeal_hearing_chairpersonFirstName, ' ')
        : '',
    },
    appeal_hearing_chairpersonLastName: {
      label: 'Appeal Hearing Chairperson Last Name',
      type: 'text',
      value: caseData.appeal_hearing_chairpersonLastName
        ? toTitleCase(caseData.appeal_hearing_chairpersonLastName, ' ')
        : '',
    },
    initial_hearing_hearingLocation: {
      label: 'Place',
      type: 'text',
      value: caseData.initial_hearing_hearingLocation ? toTitleCase(caseData.initial_hearing_hearingLocation, ' ') : '',
    },
    dismissalDate: {
      label: 'Dismissal Date',
      type: 'date',
      value: caseData.dismissalDate ? caseData.dismissalDate : '',
    },
    finalRemunerationDate: {
      label: 'Final Remuneration Date',
      type: 'date',
      value: caseData.lastRemunerationDate ? caseData.lastRemunerationDate : '',
    },
    suspensionStartDate: {
      label: 'Suspension Start Date',
      type: 'date',
      value: caseData.suspensionStartDate ? caseData.suspensionStartDate : '',
    },
    suspensionEndDate: {
      label: 'Suspension End Date',
      type: 'date',
      value: caseData.suspensionEndDate ? caseData.suspensionEndDate : '',
    },
    suspensionPeriod: {
      label: 'Suspension Period',
      type: 'text',
      value: caseData.suspensionPeriod ? caseData.suspensionPeriod : '',
    },
    returnToWorkDate: {
      label: 'Return to Work Date',
      type: 'date',
      value: caseData.returnToWorkDate ? caseData.returnToWorkDate : '',
    },
    contactName: {
      label: 'Contact Name',
      type: 'text',
      value: caseData.contactPersonInCaseOfCCMA ? toTitleCase(caseData.contactPersonInCaseOfCCMA, ' ') : '',
    },
    contactEmail: {
      label: 'Contact Email',
      type: 'text',
      value: caseData.emailAddress,
    },
    contactAddress: {
      label: 'Contact Address',
      type: 'text',
      value: caseData.physicalAddress,
    },
    demotionNewJobTitle: {
      label: 'New Job Title',
      type: 'text',
      value: caseData.demotionNewJobTitleId ? caseData.demotionNewJobTitleId : '',
    },
    demotionNewJobLevel: {
      label: 'New Job Level',
      type: 'text',
      value: caseData.demotionNewJobLevelId ? caseData.demotionNewJobLevelId : '',
    },
    demotionNewDepartment: {
      label: 'New Department',
      type: 'text',
      value: caseData.demotionNewDepartmentId ? caseData.demotionNewDepartmentId : '',
    },
    demotionNewRemunerationPackage: {
      label: 'New Remuneration Package',
      type: 'text',
      value: caseData.demotionNewRemunerationPackage ? caseData.demotionNewRemunerationPackage : '',
    },
    demotionNewTerms: {
      label: 'New Terms',
      type: 'text',
      value: caseData.demotionNewTerms ? caseData.demotionNewTerms : '',
    },
    demotionDate: {
      label: 'Demotion Date',
      type: 'date',
      value: caseData.demotionDate ? caseData.demotionDate : '',
    },

    appealProcessBackground: {
      label: 'State briefly the background of what led to the appeal process:',
      type: 'textarea',
      value: 'This is Free text',
    },
    appellantArgumentSummary: {
      label: 'Summarise arguments of the appellant:',
      type: 'textarea',
      value: 'This is Free text',
    },
    employerRepArgumentSummary: {
      label: 'Summarise arguments of the employer representative:',
      type: 'textarea',
      value: 'This is Free text',
    },
    newEvidenceSummary: {
      label: ' Summarise any NEW evidence considered, if any:',
      type: 'textarea',
      value: 'This is Free text',
    },
    legalPrinciplesApplied: {
      label: 'Legal principles applied:',
      type: 'textarea',
      value: 'This is Free text',
    },
    conclusionsReached: {
      label: 'Conclusions reached:',
      type: 'textarea',
      value: 'This is Free text',
    },
    appealOutcomes: {
      label: 'Appeal outcomes in relation to original incapacity findings. Tick as appropriate',
      type: 'textarea',
      value: 'This is Free text',
    },
    guiltFindings: {
      label: 'Findings on guilt:',
      type: 'textarea',
      value: 'This is Free text',
    },
    overturnedGuiltFindings: {
      label: 'If overturned, new finding(s) with reasons:',
      type: 'textarea',
      value: 'This is Free text',
    },
    sanctionFindings: {
      label: 'Findings on sanction:',
      type: 'textarea',
      value: 'This is Free text',
    },
    overturnedSanctionFindings: {
      label: 'If overturned, new sanction, with reasons:',
      type: 'textarea',
      value: 'This is Free text',
    },
  };
};
