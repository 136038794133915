import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../../forms/fields/FormField.component';
import { FormConfigV2 } from '../../../../../../configs/form-configs/form-config-types';

export const initial_hearing_misconduct_executeOutcomeWrittenWarning: FormConfigV2 = {
  fields: {
    incidents: 'array',
    initial_hearing_hearingDate: 'string',
    warningValidityPeriod: 'string',
    warningExpiryDate: 'string',
  },
  caseHistoryFields: {
    incidents: {
      type: 'table',
      label: 'Incidents',
      tableColumns: ['transgression', 'correctiveAction'],
    },
    initial_hearing_hearingDate: {
      type: 'string',
      label: 'Date of Hearing',
    },
    warningValidityPeriod: {
      type: 'string',
      label: 'Warning Validity Period',
    },
    warningExpiryDate: {
      type: 'string',
      label: 'Warning Expiry Date',
    },
  },
  validationSchema: Yup.object().shape({
    warningValidityPeriod: Yup.string().required('Please select validity period'),
    warningExpiryDate: Yup.string()
      .test('date', 'Please select a valid date', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    initial_hearing_hearingDate: Yup.string()
      .test('date', 'Please select a valid date', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    incidents: Yup.array()
      .of(
        Yup.object().shape({
          transgression: Yup.string().required('Please select transgression'),
          correctiveAction: Yup.string().required('Please enter corrective action'),
        }),
      )
      .required()
      .nullable(),
  }),
};

export const initial_hearing_pp_executeOutcomeWrittenWarning: FormConfigV2 = {
  fields: {
    performanceShortfalls: 'array',
    initial_hearing_hearingDate: 'string',
    warningValidityPeriod: 'string',
    warningExpiryDate: 'string',
  },
  caseHistoryFields: {
    initial_hearing_hearingDate: {
      type: 'string',
      label: 'Date of Hearing',
    },
    warningValidityPeriod: {
      type: 'string',
      label: 'Warning Validity Period',
    },
    warningExpiryDate: {
      type: 'string',
      label: 'Warning Expiry Date',
    },
    dismissalDate: {
      type: 'string',
      label: 'Dismissal Date',
    },
    lastRemunerationDate: {
      type: 'string',
      label: 'Last remuneration date',
    },
    contactPersonInCaseOfCCMA: {
      type: 'string',
      label: 'Contact Person in Case of CCMA',
    },
    physicalAddress: {
      type: 'string',
      label: 'Physical Address',
    },
    emailAddress: {
      type: 'string',
      label: 'Email Address',
    },
    physicalExitDate: {
      type: 'string',
      label: 'Physical Exit Date',
    },
    periodOfUnpaidSuspension: {
      type: 'string',
      label: 'Period of unpaid suspension',
    },
    returnToWorkDate: {
      type: 'string',
      label: 'Return to work Date',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: ['shortfallDescription', 'correctiveAction'],
    },
  },
  validationSchema: Yup.object().shape({
    warningValidityPeriod: Yup.string().required('Please select validity period'),
    warningExpiryDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Required'),
    hearingDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Required'),
    performanceShortfalls: Yup.array()
      .of(
        Yup.object().shape({
          shortfallDescription: Yup.string().required('Please select transgression'),
          correctiveAction: Yup.string().required('Please enter corrective action'),
        }),
      )
      .required()
      .nullable(),
  }),
};
